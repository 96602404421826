.icons-list {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
    font-size: 0;
}
.icons-list > li:first-child {
    margin-left: 0;
}
.icons-list > li {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    font-size: 13px;
}
.icons-list > li > a {
    color: inherit;
}
.heading-elements a{
    color: #4d4d4d;
}
.col-md-5ths{
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}
body{
    background-color: rgba(242, 242, 242, 0.5);
    font-size: 12px;
}
.content {
padding: 15px 30px 15px 35px;
}
.content:first-child {
padding-top: 100px;
}
.navbar {
border-width: 0;
}
.header-highlight .navbar-header {
border-bottom: none;
}

input.select2-search__field::placeholder,
input.form-control::placeholder{
    color: #CECECE;
}
select.form-control,
textarea.form-control,
input.form-control{
    border: 1px solid #ddd;
    color: #5e5e5e;
}
.login-form i{
    color: #ccc;
}
.login-container .page-container .login-form{
    border:none;
    width:350px;
    padding:70px 50px;
    margin-top:30px;
}
@media (max-width: 480px) {
    .login-container .page-container .login-form{
        width: 100%;
    }
}
.shadow{
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.50);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
}
.logo-login{
    max-width:280px;
    margin:0 auto;
    display: block;
}
.logo-login img{
    width:100%;
}
.panel{
    border-radius: 2px;
}
.breadcrumb-line-component:not([class*=bg-]){
    border: none;
}
.text-size-large{
    font-size: 15px!important;
}
.text-size-large i{
    font-size: 17px!important;
}
.text-size-medium,
.text-size-medium i{
    font-size: 13px!important;
}
.tab-nav-overzicht li a{
    padding:10px 0px;
    background-color: #0090a9;
    color: #fff
}
.tab-nav li a{
    padding:10px 15px;
    background-color: white;
}
.tab-nav li a.active i{
    color: #fff!important;
    font-weight: 600;
}

.tab-nav-overzicht li a.active ,
.tab-nav-overzicht li a.active i,
.tab-nav-overzicht.schade-tab li a.active i,
.tab-nav-overzicht.schade-tab li a.active {
    background-color: #037a97;
    color: #fff;
}

.tab-nav li a.active {
    background-color: #04466e;
    color: #fff !important;
}
.breadcrumb-line-component{
    border-radius: 5px;
}
.tab-nav li:first-child,
.tab-nav li:first-child a{
    border-radius: 5px 0 0 5px;
}
.tab-nav li:last-child,
.tab-nav li:last-child a {
    border-radius: 0 5px 5px 0;

}
.panel{
    border:1px solid #f2f2f2;
}
.datatable-footer,
.panel-body + .dataTables_wrapper,
.table > thead > tr > th,
.table > tbody > tr > td{
    border-color: #f2f2f2!important;
}
.datatable-header,
.table > thead > tr > th{
    border-bottom: 0;
}
th{
    color: #04466e;
    font-weight: 700!important;
}

.sidebar-category .category-title{
    background-color: #f2f2f2;
    border-bottom: 1px solid #fff;
}
.content-wrapper + .sidebar-default {
    border-left: 1px solid #f2f2f2;
}
.category-title > span {
    text-transform: none !important;
}
.margin-auto{
    display: block;
    margin:0 auto;
}
.navbar-header{
    margin:0 auto;
}
.navbar-brand{
    max-width:260px;
    height:auto;
    padding:0;
    overflow: hidden;
}
.navbar-brand img{
    display: block;
    height:60px;
}
/*.navbar-nav > .dropdown-user > a, .navbar-nav > .dropdown-user > a > span {*/
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
/*}*/
/*.navbar-nav > li > a {*/
    /*padding-top: 20px;*/
    /*padding-bottom: 20px;*/
/*}*/
/*.navbar-nav > li > a > .badge{*/
    /*top:7px;*/
/*}*/

/*.sidebar-opposite{*/
    /*position: absolute;*/
    /*right: 0;*/
    /*height: 100%;*/
    /*display: none;*/
/*}*/

.input-table{
    border: 1px solid #ddd!important;
}
.datatable-header{
    display: none;
}
.dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_paginate {
    float: right!important;
}
.dataTables_wrapper .bottom{
    padding: 5px 5px;
}
.letterFilter{
    letter-spacing: 3px;
    color: #b3b3b3;
}
.alphabet{
    padding: 8px 0;
    margin-bottom: 20px;
}
.dataTables_paginate .paginate_button.current{
    background-color: #04466e;
}
.panel-flat > .panel-heading + .panel-body {
    padding-top: 15px;
    padding-bottom: 5px;
    /*border-top: 1px solid #f2f2f2;*/
}
.panel-flat > .panel-heading{
    padding-top: 5px;
    padding-bottom: 5px;
}
.sidebar-main{
    background-color: #04466e;
}

.navigation li a,
.navigation .navigation-header {
    color: rgba(255, 255, 255, 1);
}
.pt-57{
    padding-top: 57px;
}
.header-highlight .navbar-header {
    margin-top: 0;
    margin-bottom: 0;
}
@media (min-width: 769px) {
    .navbar {
        padding-left: 0px;
        padding-right: 20px;
    }
}
.navbar-brand img {
    display: block;
    width: 60%;
    height: auto;
    margin: 0 auto;
}
.navbar-nav > li > a {
    padding-top: 26px;
    padding-bottom: 26px;
}

.navbar-nav > .dropdown-user > a{
    padding-top: 13px;
    padding-bottom: 13px;
}
.navbar-nav > li > a > .label, .navbar-nav > li > a > .badge {
    position: absolute;
    top: 13px;
    right: 0;
}
.sidebar-main-toggle i{
    font-size: 20px;
    font-weight: bold;
    color: #0090a9;
}
.sidebar-opposite .sidebar-content{
    top:73px;
}
@media (min-width: 769px){
    .page-header{
        margin-top:73px;
    }
}

.btn-action-table i{
    font-size: 13px;
}

.btn-action-table{
    background-color: #f9f9f9;
    border-radius: 15px;
    padding: 2px 12px;
    font-size: 12px;
}
.btn-action{
    background-color: #fff;
    border-radius: 15px;
    color: #000;
    font-weight: bold;
}
.page-header .actionButtons{
    padding-left: 35px;
    padding-right: 35px;
    min-height: 36px;
}
.page-header-content {

    padding: 0 35px;
}


.dashboard-short i{
    font-size: 40px;
}
.dashboard-short .panel-heading,
.dashboard-short .panel-footer,
.dashboard-short .panel-footer .btn,
.dashboard-short{
    text-align: center;
    background-color: #0090a9;
    color: #fff;
}
.dashboard-short .panel-footer,
.dashboard-short .heading-elements,
.dashboard-short .quickButtons{
    width: 100%!important;
    margin: 0!important;
    padding: 0!important;
}
.dashboard-short .quickButtons .btn{
    width: 50%;
    margin: 0;
    border-radius: 0;
    border-color: #0090a9;
}
.dashboard-short .quickButtons .btn.left{
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
}
.dashboard-short .quickButtons .btn.right{
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}


.pt-30{
    padding-top: 30px!important;
}
.nav > li > a.active:hover i,
.nav > li > a.active:hover {
    color: #ffffff!important;

}
.tab-nav-overzicht > li > a:hover {
    text-decoration: none;
    background-color: #037a97;
}
.bg-light-grey{
    background-color: #f2f2f2;
}
.modal-header,
.modal-body,
.modal-footer{
    padding:15px 35px!important;

}
.modal-header,
.modal-content {
    border-radius: 0!important;
}
.modal {
    text-align: center;
}
@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
.modal-header[class*=bg-] .close {
    color: #000;
}
.list-group .identificatie{
    display: block;
    }
.panel{
    color: #4d4d4d;
}
.heading-elements:not(.visible-elements) .icons-list {
    margin-top: 5px;
}

.bg-success{
    background-color: #7ac943;
}
.bg-danger{
    background-color: #ff1d25;
}

.panel-heading{
    border-bottom: 1px solid #f2f2f2;
}
.table > thead > tr > th{
    border-bottom: 1px solid #f2f2f2;
}

.panel-flat > .panel-heading + .table, .panel-flat > .panel-heading + .table-responsive{
    border-top: none;
}



.table > thead > tr > th:first-of-type,
.table > thead > tr > td:first-of-type,
.table > tbody > tr > th:first-of-type,
.table > tbody > tr > td:first-of-type{
    padding-left: 20px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
    padding:5px 5px;
}
@media (min-width: 1025px) {
    .flex {
        display: flex;
        margin-bottom: 20px !important;
    }

    .flex > div .content-group-lg,
    .flex > div .content-group-lg .panel {
        height: 100%;
        margin-bottom: 0;
    }

    .flex > div .content-group-lg {
        flex: 1;
        margin-bottom: 0 !important;
    }
}
.no-padding{
    padding: 0 !important;
}
/*.form-group{*/
    /*overflow: hidden;*/
/*}*/
.checkbox-inline,
.radio-inline{
    line-height: 1.6;
}
/*.form-group label:not(.checkbox-inline){*/
    /*line-height: 36px;*/
/*}*/

.login-container .form-group label:not(.checkbox-inline){
    line-height: 1.5384616;
}

.modal .modal-body{
    overflow: auto;
    max-height:calc(~"95vh - 130px");
}
.modal .modal-dialog .modal-content{
    max-height: 95vh;
    overflow: auto;
}

textarea{
    max-width: 100%;
    min-width: 100%;
}
.mb-7{
    margin-bottom: 7px!important;
}
.pr-13{
    padding-right: 13px!important;
}
@media (min-width: 769px) {
    .modal-full {
        width: 80%;
         margin-left: auto;
         margin-right: auto;
    }
}
@media (min-width: 1200px) {
    .modal-full {
        width: 65%;
         margin-left: auto;
         margin-right: auto;
    }
    .order-md-1{
        order: 1;
    }
    .order-md-2{
        order: 2;
    }
}
@media (min-width: 1600px) {
    .modal-full {
        width: 55%;
         margin-left: auto;
         margin-right: auto;
    }
}
.column-icon{
    width:40px;
}

.daterangepicker{
    z-index: 99999!important;
}
.phoneLabels label{
    line-height: 12px!important;
    margin-top:5px;
}
.checkbox .checker, .checkbox-inline .checker {
    top: 4px!important;
}
.radio, .checkbox {
    margin-top: 0;
    margin-bottom: 0;
}
.page-title {
    padding: 32px 36px 16px 0px;

}
.fileinput-upload-button{
    display: none;
}

div.alphabet {
    display: inline-block;
    max-width: 50%;
    margin-bottom: 1em;
    width: 100%;
}

div.alphabet span {
    display: table-cell;
    color: #3174c7;
    cursor: pointer;
    text-align: center;
    width: 1.5%
}

div.alphabet span:hover {
    text-decoration: underline;
}

div.alphabet span.active {
    color: black;
}
.sidebar-content .form-group label:not(.checkbox-inline) {
    line-height: 1.5384616;
}

.bg-main{
    background-color: #04466e!important;
}
.datatable-scroll-wrap{
    overflow-x: visible;
}
.icons-list a[data-action="collapse"]:first-of-type:after{
    /*content: ;*/
}
.closeSidebar{
    font-size: 16px;
    min-width: 16px;
    text-align: center;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

table.dataTable.dt-checkboxes-select tbody tr,
table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
    cursor: pointer;
}

table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
    text-align: center;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
    margin-left: 0.5em;
}

@media screen and (max-width: 640px) {
    div.dataTables_wrapper span.select-info,
    div.dataTables_wrapper span.select-item {
        margin-left: 0;
        display: block;
    }
}
.voortgang.laat{
    background-color: #FF0000!important;
}
.voortgang.bezig{
    background-color: #FFFF00!important;
}
.voortgang.afgerond{
    background-color: #00FF00!important;
}

.dtr-inline.collapsed tbody tr td:first-child, .dtr-inline.collapsed tbody tr th:first-child {
    position: relative;
    cursor: pointer;
    white-space: normal;
}

.text-dark{
    color: #4d4d4d;
}
.tabbable .modal-header .close {
    top: 25%;
}
.tabbable .modal-body{
    height: 70vh!important;
}
.tabbable .modal-header{
    padding: 0px!important;
}
.tabbable .modal-content{
    border:none !important;
}
.tabbable .modal-content .breadcrumb-line-component:not([class*=bg-]){
    background-color: #0090a9;
}
.tabbable .modal-title{
    margin: 15px 35px;
}
.ztop{
    z-index: 9999;
}
.content-wrapper{
    position: relative;
}
.ptb-32{
    padding-top: 32px!important;
    padding-bottom: 32px!important;
}
.sidebar-mobile-main-toggle i,
.icon-tree5{
    color: #4e4e4e;
}
.ptb-18{
    padding-top: 18px!important;
    padding-bottom: 18px!important;
}
.search-input-nav{
    width: 100%;
}

@media (min-width: 769px){
    .search-input-nav {
        max-width: 200px;
    }
}

#search-history{
    position: absolute;
    top:60px;
    background-color: white;
    border: 1px solid #f2f2f2;
    width: 100%;
    z-index: 950;

    display: none;
}
#search-history li{
    font-size: 13px;
    padding: 5px 0;
}
.style-type-none{
    list-style-type: none;
}
.dataTable span{
    word-break: break-word;
}

@media (max-width: 767px) {
    .daterangepicker.dropdown-menu {
        display: none!important;
    }
    .modal-dialog{
        width: 100%;
    }

    .xs-bg-grey{
        background: #f2f2f2;
        border: 1px solid #e4e4e4;
    }
    .xs-mt-10{
        margin-top: 10px!important;
    }
    .xs-pb-0{
        padding-bottom: 0!important;
    }
    .navbar-nav > li > a > .badge {
        position: absolute;
        top: 13px;
        left: 22px;
        right: auto;
    }
}

@media (max-width: 1024px) {
    .sm-mb-10{
        margin-bottom: 10px;
    }
    .tab-nav li a {
        padding: 10px;
    }

}
@media (max-width: 1120px) {
    .sm-mb-10{
        margin-bottom: 10px;
    }

    .alphabet{
        max-width: 100%!important;
    }

}
@media (min-width: 1025px) {
    .md-no-padding-left{
        padding-left: 0;
    }
    .md-no-padding-right{
        padding-right: 0;
    }
}

@media (min-width: 769px) and (max-width: 1024px)  {
    .modal-dialog {
        width: 675px;
        margin: 30px auto;
    }
}
.dataTable tr.child .dtr-title {
    display: table-cell;
    font-weight: 500;
    padding-right: 5px;
}
@media (min-width: 769px){
    .navbar-right .navbar-nav {
        margin-left: 0;
    }
}
.fc-view > table {
    min-width: 1px;
}
.heading-elements-tab{
    top: 23px;
    color: #4d4d4d;
    margin-right: 20px;
}
.heading-elements-head {
    top: 32px;

}
.heading-elements-tab,
.heading-elements-head {
    cursor: pointer;
    display: block;
    line-height: 1;
    position: absolute;
    right: 0;
    margin-top: -14px;
}
.form-group-block .form-group{
    display: block;
    width: 100%;
    overflow: visible;
    clear: both;
}
.modal-body .form-group label[class^="col-lg"]{
    font-weight: bold;
}

.dropup .dropdown-menu{
    bottom: auto;
}
.nav>li>a {
    padding-left: 14px;
    padding-right: 14px;
}
.lh-34{
    line-height: 34px;
}
@media (min-width: 1239px){

    .tabbable .nav>li{
        height: 1px;
    }
}
@media (max-width: 768px){
    .modal-dialog {
        width: calc(~"100% - 20px");
    }
    .tabbable .modal-header .close {
        top: 7%;
    }
}
.tabbable .nav>li>a {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
}

.breakword{
    word-break: break-word;
}
@media (min-width: 768px) and (max-width: 1239px){
    .tabbable .nav-justified > li {
        width: 100%;
    }
    .nav-justified > li {
        float: none;
    }
    .nav > li {
        position: relative;
        display: block;
    }
    .nav#relatiegroepen > li {
        position: relative;
        display: table-cell;
    }
}
.t-72{
    top: 72px!important;
}
.identificatie.saldo{
    font-size: 32px;
}
.sidebarModal{
    z-index: 9998;
    right: -16px;
    border-left: none;
}
.sidebarModal .sidebar-content{
    top: 0;
}
/*Firefox targeted*/
@-moz-document url-prefix() {
    .tabbable .nav > li {

        height: auto;

    }
}
.form-group .checkbox-inline input{
    margin-top: 0;
}
/*.checkbox .styled,*/
/*.form-group .radio-inline input{*/
    /*height: 36px;*/
    /*margin-top: 0;*/
/*}*/
@media (min-width: 768px) {
    .modal-full.modalSidebar {
        right: 130px;
    }
}
.td-small{
    width: 80px;
}
.td-medium{
    width: 200px;
}
textarea{
    resize: vertical;
}
input.error-input,
select.error-input,
textarea.error-input,
.select2.error-input,
div.error-input{
    border: 2px solid #ff0000!important;
}

.tribute-container {
    min-width: 300px;
}

.tribute-container ul, .tribute-container li.highlight {
    background: #04466e;
    color: white;
}

.tribute-container li {
    border-bottom: 1px solid white;
}

.theme_xbox_xs .pace_activity, .theme_xbox_xs .pace_activity::before, .theme_xbox_xs .pace_activity::after, .theme_xbox_sm .pace_activity, .theme_xbox_sm .pace_activity::before, .theme_xbox_sm .pace_activity::after, .theme_xbox .pace_activity, .theme_xbox .pace_activity::before, .theme_xbox .pace_activity::after {
    border-top-color: #0090a9;
}
.table-striped > tbody > tr:nth-of-type(odd){
    background-color: #fafafa;
}
.navbar-title{
    margin-top: 13px;
}
@media(min-width: 1200px){
    .navbar-title{
        max-width: 29%;
    }
}
@media(min-width: 1600px){
    .navbar-title{
        max-width: 40%;
    }
}
@media (max-width: 575px){
    .navbar-brand {
        max-width: 175px;
    }
    .navbar-brand img{
        width: 90%;
    }
}
.btn{
    padding: 4px 12px
}
@media(max-width: 768px){
    .btn{
        padding: 4px;
    }
}
.actionButtons .btn{
    padding: 3px 12px;
}
.form-group{
    margin-bottom: 10px;
}
.form-control{
    height: 30px;
    padding: 5px 12px;
}
label.identificatie{
    line-height: 30px;
}
@media (min-width: 1200px){
    .form-horizontal .control-label[class*=col-lg-]{
        padding-top: 6px;
    }
    .form-horizontal .control-label{
        padding-bottom: 6px;
    }
}
.input-group-addon{
    padding: 6px 12px;
}
.select2-selection--single{
    height: 30px;
    padding: 5px 12px;
}

.modal label{
    margin-bottom: 3px;
}
.modal .form-group {
    margin-bottom: 5px;
}
.modal .content-group-lg{
    margin-bottom: 0px!important;
}
.modal h6{
    margin-bottom: 5px;
}
.modal .col-md-4 > .form-group > label,
.modal .col-md-6 > .form-group > label{
    font-weight: bold;
}
.table-hover > tbody > tr:hover {
    background-color: #f8f8f8!important;
}
.select2-selection--multiple .select2-selection__choice{
    padding: 3px 10px;
}
.select2-selection--multiple:not([class*=bg-]):not([class*=border-]),
.select2-selection--multiple .select2-selection__rendered{
    height: 30px;
}
.select2-selection--multiple .select2-search--inline .select2-search__field{
    margin-top: 0;
    padding: 6px 0;
}
.modal .radio-inline{
    line-height: 30px;
    height: 30px;
}
.modal .radio-inline input[type="radio"]{
    margin-top: 0;
    height: 30px;
}
.text-size-20{
    font-size: 20px;
}
.l-height-30{
    line-height: 30px;
}
.font-weight-normal{
    font-weight: normal!important;
}
.bg-grey-200{
    background-color: #cccccc;
}
.bg-danger-200{
    background-color: #ffaaaa;
}
.bg-success-200{
    background-color: #9defa1;
}
.bg-warning-200{
    background-color: #ffb05f;
}
@media(max-width: 768px){
    .scroll-table-wrapper table{
        min-width: 450px;
    }
    .scroll-table-wrapper{
        overflow-x: scroll;
    }
    /*.scroll-table-wrapper .w-5{*/
    /*    width: 5%!important;*/
    /*}*/
    /*.scroll-table-wrapper .w-15{*/
    /*    width: 15%!important;*/
    /*}*/
    /*.scroll-table-wrapper .w-10{*/
    /*    width: 10%!important;*/
    /*}*/
    /*.scroll-table-wrapper .w-20{*/
    /*    width: 20%!important;*/
    /*}*/
    /*.scroll-table-wrapper .w-25{*/
    /*    width: 25%!important;*/
    /*}*/
    /*.scroll-table-wrapper .w-30{*/
    /*    width: 30%!important;*/
    /*}*/
}
@media(max-width: 1024px){
    .w-md-20{
        width: 20%!important;
    }
}
@media(max-width: 575px){
    .w-xs-40{
        width: 40%!important;
    }
}
.sidebar-default{
    background-color: #04466e!important;
}

.bg-primary-300 {
    background-color: #0090a9;
    border-color: #0090a9;
    color: #fff;
}
.bg-primary-400 {
    background-color: #037a97;
    border-color: #037a97;
    color: #fff;
}
.bg-primary-800 {
    background-color: #04466e;
    border-color: #04466e;
    color: #fff;
}

.bg-danger {
    background-color: #F44336;
    border-color: #F44336;
    color: #fff;
}
.bg-danger-400 {
    background-color: #EF5350;
    border-color: #EF5350;
    color: #fff;
}
.bg-danger-700 {
    background-color: #D32F2F;
    border-color: #D32F2F;
    color: #fff;
}

.bg-success-400 {
    background-color: #66BB6A;
    border-color: #66BB6A;
    color: #fff;
}
.bg-warning-400 {
    background-color: #FF7043;
    border-color: #FF7043;
    color: #fff;
}
.bg-blue {
    background-color: #03A9F4;
    border-color: #03A9F4;
    color: #fff;
}
.bg-blue-400 {
    background-color: #29B6F6;
    border-color: #29B6F6;
    color: #fff;
}
.bg-grey-400 {
    background-color: #888;
    border-color: #888;
    color: #fff;
}
.bg-teal-400 {
    background-color: #26a69a;
    border-color: #26a69a;
    color: #fff;
}
.text-primary,
.text-primary:hover,
.text-primary:focus {
    color: #2196F3 !important;
}
.text-primary-400,
.text-primary-400:hover,
.text-primary-400:focus {
    color: #42A5F5 !important;
}
.text-primary-700,
.text-primary-700:hover,
.text-primary-700:focus {
    color: #1976D2 !important;
}
.text-primary-800,
.text-primary-800:hover,
.text-primary-800:focus {
    color: #04466e !important;
}
.text-danger,
.text-danger:hover,
.text-danger:focus {
    color: #F44336 !important;
}
.text-danger-400,
.text-danger-400:hover,
.text-danger-400:focus {
    color: #EF5350 !important;
}
.text-danger-700,
.text-danger-700:hover,
.text-danger-700:focus {
    color: #D32F2F !important;
}
.text-success-400,
.text-success-400:hover,
.text-success-400:focus {
    color: #66BB6A !important;
}
.text-blue-700,
.text-blue-700:hover,
.text-blue-700:focus {
    color: #0288D1 !important;
}
.text-green-700,
.text-green-700:hover,
.text-green-700:focus {
    color: #689F38 !important;
}

.text-default,
.text-default:hover,
.text-default:focus {
    color: #333333 !important;
}

.text-white,
.text-white:hover,
.text-white:focus {
    color: #fff!important;
}
.text-black{
    color: #000;
}
.sidebar-fixed .sidebar-content{
    background-color: #04466e;
}
.visibility-hidden{
    visibility: hidden!important;
}
.blockElement{
    top: 10%!important;
}
.heading-elements{
    height: 30px;
    margin-top: -15px;
}
.heading-elements.d-flex .select2{
    width: 0;
    flex-grow: 1;
    margin-right: 10px;
}
.dropdown-user .dropdown-toggle{
    padding-top: 20px!important;
    padding-bottom: 20px!important;
}
.dropdown-search .dropdown-toggle{
    padding-top: 22px!important;
    padding-bottom: 21px!important;
}
.text-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#notitiePanels .panel{
    overflow: auto;
}
#notitiePanels .panel-heading{
    border-bottom: none;
}
#notitiePanels table{
    min-width: 650px;
    border-top: 1px solid #f2f2f2;
}
.documenten-table,
.taken-table{
    width: 100%!important;
}
input[type="date"].form-control{
    line-height: normal!important;
}
.progress {
    position: relative;
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    overflow: hidden;
    font-size: 13px;
    background-color: #eee;
    border-radius: .1875rem;
    box-shadow: inset 0 0.0625rem 0.0625rem rgba(0,0,0,.1);
}
.bg-teal {
    background-color: #009688;
}
.progress-bar {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
}
.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2196f3;
    transition: width .6s ease;
}
.bug-report{
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: #0090a9;
    color: #ffffff;
    font-size: 20px;
    border-radius: 50%;
    padding: 10px 15px;
    margin: 5px;
}
.dataTable tr td{
    white-space: nowrap!important;
}
.dataTable tr td span.dtr-title,
.dataTable tr td span.dtr-data{
    white-space: normal!important;
}
.start2nd > tbody > tr > td:nth-child(2) {
    padding-left: 20px;
}
.start2nd.dtr-inline.collapsed tbody tr td:before{
    margin-right: 0;
}
.w-auto{
    width: auto!important;
}
.input-group.taken {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}
.input-group.taken>.custom-select:not(:last-child), .input-group.taken>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group.taken>.custom-file, .input-group.taken>.custom-select, .input-group.taken>.form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group.taken .input-group-append {
    margin-left: -1px;
}
.input-group.taken .input-group-append, .input-group.taken .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}
.input-group.taken>.input-group-append>.btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group.taken .input-group-prepend {
    margin-right: -1px;
}
.input-group.taken .input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .4375rem 1.25rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.5385;
    color: #333;
    text-align: center;
    white-space: nowrap;
    border-radius: .1875rem;
    font-size: 14px;
}
.export-button{
    padding: 4px 12px;
    background-color: #f9f9f9;
    border-radius: 15px;
    font-size: 12px;
}
@media (min-width: 769px) and (max-width: 1024px) {
    .navbar ul.nav > li > a {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.form-control[disabled]::placeholder, .form-control[readonly]::placeholder, fieldset[disabled] .form-control::placeholder {
    color: #999999;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #dedede;
    opacity: 1;
}

.regiobank-color-mono {
    background-color: #e2c100;
    border-radius: 0px!important;
    color: white;
    padding: 7px 12px !important;
}

.regiobank-color-multi {
    background-color: #d90800;
    border-radius: 0px!important;
    color: white;
    padding: 7px 12px !important;
}

.regiobank-color-fallback {
    background-color: #e46c04;
    border-radius: 0px!important;
    color: white;
    padding: 7px 12px !important;
}

.tab-nav-overzicht li a.regiobank,
.tab-nav-overzicht li a.regiobank i{
     background-color: #d90800;
}

.regiobank-color-multi:hover, .regiobank-color-mono:hover, .regiobank-color-fallback:hover {
    color: white;
}

.tab-nav-overzicht > li > a.regiobank:hover,
.tab-nav-overzicht > li > a.regiobank:hover i,
.tab-nav-overzicht li a.regiobank.active,
.tab-nav-overzicht li a.regiobank.active i{
    background-color: #b30700;
}
.tab-nav-overzicht li a.bg-danger,
.tab-nav-overzicht li a.bg-danger i{
     background-color: #e46c04;
}

.tab-nav-overzicht > li > a.bg-danger:hover,
.tab-nav-overzicht > li > a.bg-danger:hover i,
.tab-nav-overzicht li a.bg-danger.active,
.tab-nav-overzicht li a.bg-danger.active i{
    background-color: #db6600;
}
tr.regiobank {
    background-color: #ffadb0!important;
}
.table-hover > tbody > tr.regiobank:hover {
    background-color: #ff6c72!important;
}
.pNumber{
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 5px;
}
.pNumber.positive{
    color: #33cc83;
}
.pNumber.danger{
    color: #f85769;
}
.prestaties{
    padding: 20px 20px 40px!important;
}
.prestaties .title{
    font-size: 14px;
    font-weight: 900;
    color: #000000  ;
}
.prestaties .block{
    text-align: center;
    padding: 25px 15px;
    line-height: 1;
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
}
.prestaties .block:nth-child(1),
.prestaties .block:nth-child(3) {
    border-right: 1px solid #e1e1e1;
}
.prestaties .block:nth-child(3),
.prestaties .block:nth-child(4) {
    border-bottom: 0;
}
.d-flex{
    display: block;
}
@media(min-width: 1200px){

    .prestatiesBlock{
        width: 40%;
    }
    .belactiesBlock{
        width: 60%;
    }
    .d-flex{
        display: flex;
    }
}
@media(min-width: 1460px){

    .prestatiesBlock{
        width: 25%;
    }
    .belactiesBlock{
        width: 75%;
    }
}

.prestaties .small{
    font-size: 12px;
    color: #cccccc;
}
.prestatiesWeek{
    font-size: 14px;
    color: #b0b0b0;
    font-weight: normal;
    text-transform: uppercase;
}

.h-100{
    height: 100%;
}
.h-80{
    height: 80%;
}
.belacties{
    background-image: url("/assets/images/backgrounds/belacties.jpg");
    background-size: cover;
    background-position: top center;
    height: 359px;
    color: #ffffff;
}
/*.belactiesBlock .panel{*/
/*    background-color: transparent;*/
/*    border: transparent;*/
/*    box-shadow: none;*/
/*    color: #ffffff;*/
/*    height: 100%;*/
/*}*/
.belacties .btn-border{
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    border-radius: 50px;
    padding: 8px 30px;
    margin-top: 10px;
}
.align-items-center{
    display: flex;
    align-items: center;
}
.belacties .block{
    text-align: center;
    margin: 0 auto;
}
.belacties .amount{
    font-size: 48px;
    font-weight: 600;
}
.belacties .middle .amount{
    font-size: 150px;
    line-height: 1;
}
.belacties .title{
    font-size: 16px;
    text-transform: uppercase;
}

.belactiesMobile{
    display: none;
}
@media(max-width: 1024px){
    .belacties .middle{
        width: 100%;
    }
    .belacties .side{
        display: none;
    }
}
@media(max-width: 1024px){

    .belacties{
        background-image: url("/assets/images/backgrounds/belacties-mobile.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #0090a9;
        background-position: center;
    }
    .belactiesMobile{
        display: block;
        background-color: #0090a9;
        background-image: url("/assets/images/backgrounds/belacties-mobile.jpg");
        background-position: center right;
        background-size: contain;
        background-repeat: no-repeat;
        color: #ffffff;
        padding: 16px 20px;
    }
    .belactiesMobile .btn-border{
        border-radius: 50%;
        background-color: #ffa24e;
    }
    .belactiesMobile .block{
        width: 100%;
    }
    .belactiesMobile .amount{
        float: right;
        width: 20px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
    }
    .belactiesMobile .title{
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
    }
}
.pr-35{
    padding-right: 35px;
}
#notitiePanels table p{
    margin-bottom: 0;
}
#notitiePanels .note-editor .note-editable{
    padding: 5px 10px;
    min-height: 75px;
}
.d-none{
    display: none;
}
.border-top-light{
    border-top: 1px solid #f2f2f2;
}

.w-100 {
    width: 100%;
}
.flex > div .content-group-lg .panel ~.panel {
    height: auto;
}
.h-auto {
    height: auto;
}
.no-border-radius-left .select2-selection--single{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.dataTable tr td.white-space-initial {
    white-space: initial !important;
}
html {
  height: 100%;
}
body {
  position: relative;
  min-height: 100%;
}
a {
  cursor: pointer;
}
a:focus {
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: -0.015em;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 13px;
}
a,
button,
input,
textarea {
  outline: 0;
}
svg {
  display: block;
}
.svg-inline svg {
  display: inline-block;
}
.svg-center svg {
  margin: auto;
}
.position-left {
  margin-right: 7px;
}
.position-right {
  margin-left: 7px;
}
.bg-success {
  color: #fff;
  background-color: #4CAF50;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #3d8b40;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #4CAF50;
}
.bg-warning {
  color: #fff;
  background-color: #FF5722;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #ee3900;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #FF5722;
}
.bg-danger {
  color: #fff;
  background-color: #F44336;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #ea1c0d;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #F44336;
}
.page-header {
  margin: 0;
  padding: 0;
  border-bottom-width: 0;
}
@media (min-width: 769px) {
  .page-header .heading-elements.collapse {
    display: block;
    visibility: visible;
  }
}
.page-title {
  padding: 32px 36px 32px 0;
  display: block;
  position: relative;
}
.page-title small {
  margin-left: 10px;
  display: inline-block;
}
.page-title small:before {
  content: '/';
  margin-right: 15px;
}
.page-title small.display-block {
  margin-left: 0;
  display: block;
}
.page-title small.display-block:before {
  content: none;
}
.page-title i ~ small.display-block {
  margin-left: 31px;
}
.page-title h1,
.page-title h2,
.page-title h3,
.page-title h4,
.page-title h5,
.page-title h6 {
  margin: 0;
}
@media (min-width: 769px) {
  .page-title {
    padding-right: 0;
  }
}
.page-header-content {
  position: relative;
  background-color: inherit;
  padding: 0 20px;
}
.page-header-content[class*=border-bottom-] + .breadcrumb-line {
  border-top: 0;
}
.text-black {
  font-weight: 900;
}
.text-bold {
  font-weight: 700;
}
.text-semibold {
  font-weight: 500;
}
.text-size-large {
  font-size: 14px;
}
.text-size-base {
  font-size: 13px;
}
.text-size-small {
  font-size: 12px;
}
.text-size-mini {
  font-size: 11px;
}
ul,
ol {
  padding-left: 25px;
}
.list > li,
.list > li .list > li {
  margin-top: 7px;
}
.list > li:first-child {
  margin-top: 0;
}
th {
  font-weight: 500;
}
.table {
  margin-bottom: 0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
.table tr[class*=bg-] > td,
.table tr[class*=bg-] > th {
  border-color: #fff;
}
.table tr > td[class*=bg-],
.table tr > th[class*=bg-] {
  border-color: #fff;
}
.table > thead > tr[class*=border-bottom-] > th,
.table > thead > tr[class*=border-bottom-] > td {
  border-bottom-color: inherit;
}
.table > tbody > tr[class*=border-top-] > th,
.table > tfoot > tr[class*=border-top-] > th,
.table > tbody > tr[class*=border-top-] > td,
.table > tfoot > tr[class*=border-top-] > td {
  border-top-color: inherit;
}
.table > thead > tr > th {
  border-bottom: 1px solid #bbb;
}
.table > tfoot > tr > th {
  border-top: 1px solid #bbb;
}
.table .table {
  background-color: #fff;
}
.panel-body + .table > tbody:first-child > tr:first-child > td,
.panel-body + .table-responsive > .table > tbody:first-child > tr:first-child > td,
.panel-body + .table > tbody:first-child > tr:first-child > th,
.panel-body + .table-responsive > .table > tbody:first-child > tr:first-child > th {
  border-top: 0;
}
.modal-body + .table-responsive > .table,
.modal-body + .table {
  border-bottom: 1px solid #ddd;
}
.modal-body + .table-responsive {
  border: 0;
}
.panel[class*=bg-] > .panel-body + .table,
.panel[class*=bg-] > .panel-body + .table-responsive {
  border-color: #fff;
}
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th {
  border: 0;
}
.table-xs > thead > tr > th,
.table-xs > tbody > tr > th,
.table-xs > tfoot > tr > th,
.table-xs > thead > tr > td,
.table-xs > tbody > tr > td,
.table-xs > tfoot > tr > td {
  padding: 8px 20px;
}
.table-xxs > thead > tr > th,
.table-xxs > tbody > tr > th,
.table-xxs > tfoot > tr > th,
.table-xxs > thead > tr > td,
.table-xxs > tbody > tr > td,
.table-xxs > tfoot > tr > td {
  padding: 6px 15px;
}
.panel[class*=bg-] .table-striped > tbody > tr:nth-child(odd),
.table-striped[class*=bg-] > tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover > tbody > tr:hover > th,
.table-hover > tbody > tr:hover > td {
  background-color: inherit;
}
.panel[class*=bg-] .table-hover > tbody > tr:hover,
.table-hover[class*=bg-] > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.panel[class*=bg-] .panel-body > .table .active > th,
.panel[class*=bg-] .panel-body > .table-responsive > .table .active > th,
.table[class*=bg-] .active > th,
.panel[class*=bg-] .panel-body > .table .active > td,
.panel[class*=bg-] .panel-body > .table-responsive > .table .active > td,
.table[class*=bg-] .active > td,
.panel[class*=bg-] .panel-body > .table th.active,
.panel[class*=bg-] .panel-body > .table-responsive > .table th.active,
.table[class*=bg-] th.active,
.panel[class*=bg-] .panel-body > .table td.active,
.panel[class*=bg-] .panel-body > .table-responsive > .table td.active,
.table[class*=bg-] td.active {
  background-color: rgba(0, 0, 0, 0.15);
}
label {
  margin-bottom: 7px;
  font-weight: 400;
}
select[multiple],
select[size] {
  height: 200px;
  padding: 7px;
}
select[multiple] option,
select[size] option {
  padding: 7px 12px;
  border-radius: 3px;
}
select[multiple] option + option,
select[size] option + option {
  margin-top: 1px;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 0;
}
.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[class*=bg-]:focus {
  border-color: transparent;
}
.form-control[class*=bg-]::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control[class*=bg-]:-ms-input-placeholder {
  color: #fff;
}
.form-control[class*=bg-]::-webkit-input-placeholder {
  color: #fff;
}
input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="url"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-group .form-group:last-child {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .form-group div[class*="col-md-"]:not(.control-label) + div[class*="col-md-"] {
    margin-top: 20px;
  }
}
@media (max-width: 1199px) {
  .form-group div[class*="col-lg-"]:not(.control-label) + div[class*="col-lg-"] {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .form-group div[class*="col-sm-"]:not(.control-label) + div[class*="col-sm-"] {
    margin-top: 20px;
  }
}
.radio,
.checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
}
.radio label,
.checkbox label {
  padding-left: 28px;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-left: 0;
  left: 0;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: 0;
}
.radio-inline,
.checkbox-inline {
  position: relative;
  padding-left: 28px;
}
.radio-right.radio-inline,
.radio-right label,
.checkbox-right.radio-inline,
.checkbox-right label,
.checkbox-right.checkbox-inline,
.checkbox-right label {
  padding-left: 0;
  padding-right: 28px;
}
.radio-right input[type="radio"],
.checkbox-right input[type="radio"],
.checkbox-right input[type="checkbox"] {
  left: auto;
  right: 0;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 15px;
}
.radio.disabled,
.checkbox.disabled,
.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio,
fieldset[disabled] .checkbox,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  color: #999999;
}
.input-xs {
  height: 32px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
  border-radius: 2px;
}
select.input-xs {
  height: 32px;
  line-height: 32px;
}
textarea.input-xs,
select[multiple].input-xs {
  height: auto;
}
.has-feedback .form-control {
  padding-right: 36px;
}
.form-control-feedback {
  width: 38px;
  color: #333333;
  z-index: 3;
}
input[class*=bg-] + .form-control-feedback {
  color: #fff;
}
.has-feedback-left .form-control {
  padding-right: 12px;
  padding-left: 36px;
}
.has-feedback-left .form-control-feedback {
  right: auto;
  left: 0;
}

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 769px) {
  .form-inline .form-group + .form-group {
    margin-left: 15px;
  }
  .form-inline .form-group > label {
    margin-right: 7px;
    position: relative;
    top: 1px;
  }
}
.form-horizontal .form-group .form-group {
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 769px) {
  .form-horizontal .control-label {
    padding-bottom: 7px;
    padding-top: 0;
  }
  .form-horizontal .control-label:not(.text-right) {
    text-align: left;
  }
}
@media (min-width: 480px) {
  .form-horizontal .control-label[class*=col-xs-] {
    padding-top: 8px;
  }
}
@media (min-width: 1025px) {
  .form-horizontal .control-label[class*=col-md-] {
    padding-top: 8px;
  }
}
@media (min-width: 769px) {
  .form-horizontal .control-label[class*=col-sm-] {
    padding-top: 8px;
  }
}
@media (min-width: 1200px) {
  .form-horizontal .control-label[class*=col-lg-] {
    padding-top: 8px;
  }
}
.btn {
  position: relative;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 0;
}
.btn::-moz-focus-inner {
  border: 0;
}
.btn:hover,
.btn:focus,
.btn.focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.btn[class*=bg-]:hover,
.btn[class*=bg-]:focus,
.btn[class*=bg-].focus {
  color: #fff;
}
.btn.text-size-small {
  line-height: 1.6666667;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.btn-default:active,
.btn-default.active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.btn-icon {
  padding-left: 9px;
  padding-right: 9px;
}
.btn-icon.btn-xs,
.input-group-xs > .input-group-btn > .btn.btn-icon {
  padding-left: 7px;
  padding-right: 7px;
}
.btn-link {
  color: #333333;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-block + .btn-block {
  margin-top: 10px;
}
.btn-default:focus,
.btn-default.focus,
.btn-default:hover {
  background-color: #fcfcfc;
  border-color: #ddd;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #fcfcfc;
  border-color: #ddd;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  background-color: #fcfcfc;
  border-color: #ddd;
}
.btn-default.disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.caret {
  font-style: normal;
  font-weight: normal;
  border: 0;
  margin: 0;
  width: auto;
  height: auto;
  text-align: center;
  margin-top: -1px;
}
.caret:after {
  content: '\e9c5';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropdown-menu {
  min-width: 180px;
  padding: 7px 0;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.dropdown-menu .divider {
  margin: 7px 0;
}
.dropdown-menu > li {
  position: relative;
  margin-bottom: 1px;
}
.dropdown-menu > li:last-child {
  margin-bottom: 0;
}
.dropdown-menu > li > a {
  padding: 8px 15px;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu > li > a > i{
  margin-right: 12px;
  float: left;
  margin-top: 2px;
  top: 0;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border: 0;
}
.dropup .caret:after,
.navbar-fixed-bottom .dropdown .caret:after {
  content: '\e9c6';
}
.input-group .form-control-feedback {
  z-index: 3;
}
.input-group-addon > i {
  display: block;
  top: 0;
}

.nav > li > a:focus {
  outline: 0;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color: transparent;
  color: #333333;
}
.nav-justified > li > a {
  margin-bottom: 0;
}
.navbar {
  margin-bottom: 0;
  border-width: 1px 0;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 769px) {
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.navbar-header {
  min-width: 260px;
}
.navbar-collapse > .navbar-header {
  margin-left: 0;
}
.navbar-header .navbar-nav {
  float: right;
  margin-right: 5px;
}
.navbar-header .navbar-nav > li {
  float: left;
}
.navbar-header .navbar-nav > li > a {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 768px) {
  .navbar-header .navbar-nav > li + li {
    margin-top: 0;
  }
  .navbar-collapse > .navbar-header {
    margin-left: 0;
  }
}
@media (min-width: 769px) {
  .navbar-header {
    margin-left: -20px;
  }
}
.navbar-collapse {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.navbar-collapse.collapsing {
  -webkit-transition-duration: 0.00000001s;
  transition-duration: 0.00000001s;
}
.navbar + .navbar-collapse {
  border-top: 0;
}
@media (min-width: 769px) {
  .navbar-collapse {
    text-align: left;
    margin-left: -20px;
  }
}
.header-highlight .navbar-header {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin-top: -1px;
  margin-bottom: -1px;
}
.header-highlight .navbar-header:not([class*=bg-]) {
  background-color: #263238;
  -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
}
.header-highlight .navbar-header > .navbar-nav > li > a {
  color: #fff;
}
.header-highlight .navbar-header > .navbar-nav > li > a:hover,
.header-highlight .navbar-header > .navbar-nav > li > a:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (min-width: 769px) {
  .sidebar-xs .header-highlight .navbar-header {
    min-width: 0;
    width: 56px;
    padding-left: 0;
  }
  .sidebar-xs .header-highlight .navbar-header .navbar-brand {
    padding-left: 0;
    padding-right: 0;
    background: url(../images/logo_icon_light.png) no-repeat center center;
    background-size: auto 16px;
    float: none;
    display: block;
  }
  .sidebar-xs .header-highlight .navbar-header .navbar-brand > img {
    display: none;
  }
  .sidebar-main-hidden .header-highlight .navbar-header,
  .sidebar-all-hidden .header-highlight .navbar-header {
    display: none;
  }
}
@media (min-width: 769px) {
  .sidebar-fixed-expanded .header-highlight .navbar-collapse {
    margin-left: 36px;
  }
  .sidebar-fixed-expanded .header-highlight .navbar-header {
    position: fixed;
    z-index: 99;
  }
}
@media (max-width: 768px) {
  .navbar-fixed-top {
    position: static;
  }
}
@media (min-width: 769px) {
  .navbar-top {
    padding-top: 48px;
  }
  .navbar-top .sidebar-fixed.affix {
    top: 68px;
  }
  .navbar-top-md-xs{
    padding-top: 92px;
  }
}
.navbar-bottom {
  padding-bottom: 48px;
}
.navbar-bottom-md-xs{
  padding-bottom: 92px;
}
.navbar-nav {
  margin: 0;
  text-align: left;
}
.navbar-nav > li > a {
  padding-top: 13px;
  padding-bottom: 13px;
}
.navbar-nav > li > a > .label,
.navbar-nav > li > a > .badge {
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-nav > li > a > .status-mark {
  position: absolute;
  top: 8px;
  right: 8px;
}
.navbar-nav > li > a > .status-mark-left {
  right: auto;
  left: 8px;
}
.navbar-nav > li > a > .label-left,
.navbar-nav > li > a > .badge-left {
  right: auto;
  left: 0;
}
.navbar-nav > li > a > .label-inline,
.navbar-nav > li > a > .badge-inline,
.navbar-nav > li > a > .status-mark-inline {
  position: static;
}
.navbar-nav > .dropdown-user > a,
.navbar-nav > .dropdown-user > a > span {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}
.navbar-nav > .dropdown-user > a > span {
  display: inline-block;
  padding-left: 7px;
}
.navbar-nav > .dropdown-user img {
  max-height: 30px;
  margin-top: -2.5px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .navbar-nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 8px 20px;
  }
  .navbar-nav .open .dropdown-menu > .dropdown-submenu > ul > li > a {
    padding-left: 40px;
  }
  .navbar-nav .open .dropdown-menu > .dropdown-submenu > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .navbar-nav > li + li {
    margin-top: 1px;
  }
  .navbar-nav > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-nav > li > a .caret {
    float: right;
    margin-top: 2px;
    margin-left: 5px;
  }
  .navbar-nav > li > a .label,
  .navbar-nav > li > a .badge {
    position: static;
    float: right;
  }
  .navbar-nav > .dropdown-user .caret {
    margin-top: 8px;
  }
  .navbar-default .navbar-nav {
    border-bottom: 1px solid #ddd;
  }
  .navbar-nav:last-child {
    border-bottom: 0;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 1px;
  border-top-width: 0;
}
.navbar-nav > li > .dropdown-menu .media-list {
  max-height: 340px;
  overflow-y: auto;
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  margin-bottom: 1px;
  border-top-width: 1px;
  border-bottom-width: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.navbar-btn {
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .navbar-btn {
    margin: 13px 20px;
  }
  .navbar-btn + .navbar-btn {
    margin-left: 0;
  }
}
.navbar-text {
  margin: 0;
  padding: 13px 20px;
}
.navbar-text:last-child {
  border-bottom: 0;
}
@media (min-width: 769px) {
  .navbar-text {
    padding-right: 0;
  }
  .navbar-text + .navbar-nav {
    margin-left: 15px;
  }
  .navbar-header + .navbar-text:first-child {
    padding-left: 0;
  }
}
@media (min-width: 769px) {
  .navbar-right {
    margin-right: 0;
  }
}
.navbar-default {
  border-top-color: transparent;
}
.navbar-default.navbar-default-secondary {
  background-color: #fcfcfc;
}
.page-header-content + .navbar-default {
  border-top-color: #ddd;
}
.navbar-default.navbar-fixed-bottom {
  border-top-color: #ddd;
  border-bottom-color: #fff;
}
.navbar-fixed-bottom > .navbar-default:first-child {
  border-top-color: #ddd;
}
@media (max-width: 768px) {
  .navbar-default .navbar-nav .open .dropdown-menu {
    color: #333333;
    background-color: transparent;
    border-bottom: 1px solid #ddd;
  }
  .navbar-default .navbar-nav .open > .dropdown-menu {
    border-top: 1px solid #ddd;
  }
  .navbar-default .navbar-nav .open:last-child .dropdown-menu {
    border-bottom: 0;
  }
}
.navbar-default .navbar-link {
  color: #1E88E5;
}
.navbar-default .navbar-link:hover {
  color: #166dba;
}
@media (max-width: 768px) {
  .navbar-default .dropdown-menu[class*=bg-] .label,
  .navbar-default .dropdown-menu[class*=bg-] .badge {
    color: #fff;
    background-color: #2196F3;
    border-color: transparent;
  }
  .navbar-default .dropdown-menu[class*=bg-] > .divider {
    background-color: #e5e5e5;
  }
  .navbar-default .dropdown-menu[class*=bg-] .dropdown-submenu:hover > a,
  .navbar-default .dropdown-menu[class*=bg-] .dropdown-submenu:focus > a {
    background-color: #f5f5f5;
  }
  .navbar-default .dropdown-menu .table-responsive {
    border-width: 0;
  }
  .navbar-default .dropdown-menu .dropdown-content-heading + .table-responsive {
    border-top-width: 1px;
  }
  .navbar-default .navbar-text:not([data-toggle="collapse"]) {
    border-bottom: 1px solid #ddd;
  }
  .navbar-default > .navbar-nav > li > a:not(.collapsed),
  .navbar-default > .navbar-nav > li > a:hover,
  .navbar-default > .navbar-nav > li > a:focus {
    background-color: #fcfcfc;
  }
}
.dropdown-content-heading {
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.dropdown-content-heading + .dropdown-menu-body {
  padding-top: 0;
}
.dropdown-content-heading + .dropdown-header {
  padding-top: 0!important;
}
ul.dropdown-menu .dropdown-content-heading {
  padding-left: 15px;
  padding-right: 15px;
}
ul.dropdown-menu .dropdown-content-heading:first-child {
  padding-top: 13px;
  padding-bottom: 13px;
}
.dropdown-content-heading .icons-list {
  float: right;
}
@media (min-width: 769px) {
  .dropdown-content-heading + .table-responsive {
    border-top: 1px solid #ddd;
  }
}
.dropdown-content-footer {
  background-color: #fcfcfc;
  color: #333333;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.dropdown-content-footer a {
  display: block;
  padding: 7px;
  text-align: center;
  color: #333333;
}
.dropdown-content-footer a:hover,
.dropdown-content-footer a:focus {
  background-color: #f5f5f5;
}
.dropdown-content-footer a > i.display-block {
  top: 0;
}
.dropdown-content-body {
  padding: 20px;
}
.dropdown-content-heading + .dropdown-content-body {
  padding-top: 0;
}
.dropdown-content:not(ul) {
  padding-top: 0;
  padding-bottom: 0;
}
.dropdown-content .form-inline {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .navbar .btn-group .dropdown-menu,
  .navbar .dropdown .dropdown-menu,
  .navbar .input-group .dropdown-menu {
    width: 100%;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .navbar .btn-group .dropdown-menu > li > a,
  .navbar .dropdown .dropdown-menu > li > a,
  .navbar .input-group .dropdown-menu > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar .btn-group,
  .navbar .input-group,
  .navbar .form-group:not(.has-feedback),
  .navbar .input-group-btn {
    position: static;
  }
  .navbar .select2-container {
    width: 100%!important;
  }
  .navbar-fixed-bottom .btn-group .dropdown-menu,
  .navbar-fixed-bottom .dropdown .dropdown-menu,
  .navbar-fixed-bottom .input-group .dropdown-menu {
    border-width: 1px 0 0 0;
  }
  .navbar-component .btn-group .dropdown-menu,
  .navbar-component .dropdown .dropdown-menu,
  .navbar-component .input-group .dropdown-menu {
    border-width: 0 1px 1px 1px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .table-responsive {
    margin-bottom: 0;
  }
}
.breadcrumb-line {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #ddd;
}
.breadcrumb-line:after {
  content: '';
  display: table;
  clear: both;
}
.breadcrumb-line:first-child {
  border-top-width: 0;
  border-bottom: 1px solid #ddd;
}
.page-header .breadcrumb-line:first-child {
  z-index: 994;
}
.breadcrumb-line:not([class*=bg-]) {
  background-color: #fff;
}
.page-header-content + .breadcrumb-line {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.page-header-default .page-header-content + .breadcrumb-line,
.page-header-inverse .page-header-content + .breadcrumb-line {
  margin-bottom: 0;
}
.page-header-default .page-header-content + .breadcrumb-line {
  border-bottom-width: 0;
}
.page-header-default .breadcrumb-line:not([class*=bg-]) {
  background-color: #fcfcfc;
}
.page-header-inverse .breadcrumb-line {
  border-top-width: 0;
}
.page-header-inverse .breadcrumb-line:first-child {
  border-bottom-width: 0;
}
.breadcrumb-line .breadcrumb {
  margin-right: 46px;
}
@media (min-width: 769px) {
  .breadcrumb-line .breadcrumb {
    float: left;
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .breadcrumb-line {
    z-index: 998;
    background-color: inherit;
  }
}
.breadcrumb-line-component {
  border-radius: 3px;
  padding-left: 0;
  padding-right: 0;
}
.breadcrumb-line-component:not([class*=bg-]) {
  background-color: #fff;
  border: 1px solid #ddd;
}
.page-header-default .breadcrumb-line-component:not([class*=bg-]) {
  border-width: 1px;
}
.page-header-inverse .breadcrumb-line-component:not([class*=bg-]) {
  border-width: 0;
}
.breadcrumb-line-component .breadcrumb {
  margin-left: 20px;
}
.page-header .breadcrumb-line-component {
  margin-left: 20px;
  margin-right: 20px;
}
.breadcrumb-line[class*=bg-] a,
.breadcrumb-line[class*=bg-] i {
  color: inherit;
}
.breadcrumb-line[class*=bg-] .breadcrumb > .active,
.breadcrumb-line[class*=bg-] .breadcrumb > li + li:before {
  color: rgba(255, 255, 255, 0.75);
}
.pagination {
  margin-top: 0;
  margin-bottom: -6px;
}
.pagination > li > a,
.pagination > li > span {
  min-width: 36px;
  text-align: center;
}
.pagination-separated > li > a,
.pagination-separated > li > span {
  margin-left: 2px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  min-width: 34px;
}
.pager {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0;
}
.pager li > a,
.pager li > span {
  padding: 7px 12px;
  color: #333333;
  font-size: 13px;
}
.pager li > a:hover,
.pager li > a:focus {
  border-color: #2196F3;
  color: #fff;
}
.pager li + li {
  margin-left: 10px;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  border-color: #ddd;
}
.pager.text-left {
  text-align: left;
}
.pager.text-right {
  text-align: right;
}
.pager-lg li > a,
.pager-lg li > span {
  padding: 9px 15px;
  font-size: 14px;
  line-height: 1.4285715;
}
.pager-sm li > a,
.pager-sm li > span {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.pager-xs li > a,
.pager-xs li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
.pager-rounded li > a,
.pager-rounded li > span {
  border-radius: 100px;
}
.pager-linked li > a,
.pager-linked li > span {
  border-color: transparent;
  color: #1E88E5;
}
.pager-linked li > a:hover,
.pager-linked li > span:hover {
  background-color: #2196F3;
  color: #fff;
}
.pager-linked .disabled > a,
.pager-linked .disabled > a:hover,
.pager-linked .disabled > a:focus,
.pager-linked .disabled > span {
  border-color: transparent;
}
.label {
  display: inline-block;
  font-weight: 500;
  padding: 2px 5px 1px 5px;
  line-height: 1.5384616;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1px;
  border-radius: 2px;
}
.btn .label {
  top: 0;
}
.list-group-item.active > .label,
.nav-pills > .active > a > .label,
.nav-tabs-solid > .active > a > .label,
.nav-tabs[class*=bg-] > li > a > .label {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
}
@media (min-width: 769px) {
  .list-group-item > .label {
    float: right;
  }
  .list-group-item > .label + .label {
    margin-right: 7px;
  }
}
.label > .caret,
.badge > .caret {
  margin-top: -2px;
}
.open .label.dropdown-toggle,
.open .badge.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.label[href]:hover,
.badge[href]:hover,
.label[href]:focus,
.badge[href]:focus {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.badge {
  padding: 2px 6px 1px 6px;
  font-size: 10px;
  letter-spacing: 0.1px;
  vertical-align: baseline;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 100px;
}
.alert {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}
.alert .alert-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.alert .alert-link {
  color: inherit;
}
.alert .close,
.alert .close:hover,
.alert .close:focus {
  color: inherit;
}
.alert-success,
.alert-success .close {
  color: #205823;
}
.media {
  margin-top: 20px;
  position: relative;
}
.media.panel-body {
  margin-top: 0;
}
.media,
.media-body {
  overflow: visible;
}
.media-left,
.media-right,
.media-body {
  position: relative;
}
.media-heading {
  margin-bottom: 2px;
  display: block;
}
.media-list {
  margin-bottom: 0;
}
.media-right,
.media > .pull-right {
  padding-left: 20px;
}
.media-left,
.media > .pull-left {
  padding-right: 20px;
}
.media-badge {
  position: absolute;
  left: -10px;
  top: -2px;
}
.media-badge,
.media-badge[class*=bg-] {
  border: 2px solid;
}
.media-annotation {
  color: #999999;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
}
.media-heading .media-annotation {
  margin-left: 7px;
}
.media-annotation i {
  font-size: 13px;
}
.media-annotation.dotted:not(.pull-right):before {
  content: '\2022';
  margin-right: 10px;
}
.media-header {
  white-space: nowrap;
  margin-top: 20px;
  font-weight: 500;
}
.media-header:first-child {
  margin-top: 0;
}
/* ------------------------------------------------------------------------------
*
*  # List groups component
*
*  Overrides for list groups bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.list-group {
  list-style: none;
  margin-bottom: 0;
  border: 1px solid #ddd;
  padding: 7px 0;
  border-radius: 3px;
}
.panel {
  margin-bottom: 20px;
  border-color: #ddd;
  color: #333333;
}
.panel-flat > .panel-heading + .table,
.panel-flat > .panel-heading + .table-responsive {
  border-top: 1px solid #ddd;
}
.panel[class*=bg-] > .panel-heading {
  border-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 768px) {
  .panel[class*=bg-] > .panel-heading {
    background-color: inherit;
  }
}
.panel[class*=bg-].panel-flat > .panel-heading {
  border-bottom-color: transparent;
}
.panel[class*=bg-] .panel-title {
  color: #fff;
}
.panel[class*=bg-] .table thead td,
.panel[class*=bg-] .table tbody td,
.panel[class*=bg-] .table thead th,
.panel[class*=bg-] .table tbody th {
  border-color: rgba(255, 255, 255, 0.1);
}
.panel[class*=bg-] .text-muted,
.panel[class*=bg-] .help-block,
.panel[class*=bg-] .help-inline {
  color: rgba(255, 255, 255, 0.8);
}
.panel[class*=border-top-] {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.panel[class*=border-bottom-] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.panel[class*=border-left-] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.panel[class*=border-right-] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.panel-body {
  position: relative;
}
.panel-flat > .panel-heading + .panel-body {
  padding-top: 0;
}
.panel-body + .panel-body,
.panel-body + .table,
.panel-body + .table-responsive,
.panel-body.has-top-border {
  border-top: 1px solid #ddd;
}
.panel[class*=bg-] .panel-body + .panel-body,
.panel[class*=bg-] .panel-body + .table,
.panel[class*=bg-] .panel-body + .table-responsive,
.panel[class*=bg-] .panel-body.has-top-border {
  border-top-color: rgba(255, 255, 255, 0.5);
}
.panel-body-accent {
  background-color: #fcfcfc;
}
.panel[class*=bg-] .panel-body-accent {
  background-color: rgba(0, 0, 0, 0.1);
}
.panel-heading {
  position: relative;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-flat > .panel-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}
.panel-flat > .panel-heading > .panel-title {
  margin-top: 2px;
  margin-bottom: 2px;
}
.panel-flat[class*=bg-] > .panel-heading {
  background-color: inherit;
}
.panel-white > .panel-heading {
  background-color: #fff;
  border-bottom-color: #ddd;
}
.panel-title {
  position: relative;
  font-size: 13px;
}
a.panel-title {
  display: block;
}
.panel-title img {
  max-height: 20px;
  display: inline-block;
  vertical-align: top;
}
.panel-title > small:not(.display-block),
.panel-title > .small:not(.display-block) {
  margin-left: 5px;
}
h1.panel-title,
.h1.panel-title {
  font-size: 25px;
}
h2.panel-title,
.h2.panel-title {
  font-size: 23px;
}
h3.panel-title,
.h3.panel-title {
  font-size: 21px;
}
h4.panel-title,
.h4.panel-title {
  font-size: 19px;
}
h5.panel-title,
.h5.panel-title {
  font-size: 17px;
}
h6.panel-title,
.h6.panel-title {
  font-size: 15px;
}
.icons-list a[data-action] {
  vertical-align: middle;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.icons-list a[data-action]:after {
  font-family: 'icomoon';
  font-size: 16px;
  min-width: 16px;
  text-align: center;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icons-list a[data-action="collapse"]:after {
  content: '\e9c1';
}
.icons-list a[data-action="reload"]:after {
  content: '\e9fb';
}
.icons-list a[data-action="close"]:after {
  content: '\e9b6';
}
.icons-list a[data-action="move"]:after {
  content: '\e986';
}
.icons-list a[data-action="modal"]:after {
  content: '\e9eb';
}
.panel-footer {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-footer:after {
  content: '';
  display: table;
  clear: both;
}
.close {
  text-shadow: none;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.close:hover,
.close:focus {
  outline: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.modal-content {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.modal-header {
  position: relative;
  padding-bottom: 0;
}
.modal-header[class*=bg-] {
  padding: 15px 20px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.modal-header[class*=bg-] .close {
  margin-top: -9.75px;
}
.modal-content[class*=bg-] .modal-header .close,
.modal-header[class*=bg-] .close {
  color: #fff;
}
.modal-header .close {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: 0;
}
.modal-body .close {
  margin-top: 0!important;
}
.modal-footer {
  padding-top: 0;
}
.modal-footer.text-center {
  text-align: center;
}
.modal-footer.text-left {
  text-align: left;
}
@media (min-width: 769px) {
  .modal-full {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
}
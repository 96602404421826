.sidebar {
  background-color: #263238;
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 260px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
  }
}
.sidebar:not(.sidebar-default) + .sidebar:not(.sidebar-default) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar .has-scroll {
  max-height: 192px;
  overflow-y: auto;
}
.sidebar-default {
  background-color: #fff;
  color: #333333;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  .sidebar-default {
    border-bottom: 0;
    border-right: 1px solid #ddd;
  }
  .content-wrapper + .sidebar-default {
    border-left: 1px solid #ddd;
  }
}
.sidebar-content {
  position: relative;
  padding-bottom: 8px;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite,
  .sidebar-mobile-detached .sidebar-detached > .sidebar {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 12px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.sidebar-default .category-title {
  border-bottom-color: #ddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-user + .sidebar-category .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #0090a9;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li.active > a .label,
.navigation > li.active > a .badge {
  background-color: rgba(0, 0, 0, 0.35);
  border-color: transparent;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.navigation .label,
.navigation .badge {
  float: right;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f8f8f8;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation li.active > a > [class*=text-] {
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation li .label-transparent {
  color: #333333;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation > li.active > a .label-transparent {
  color: #333333;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
@media (min-width: 769px) {
  .sidebar-fixed .sidebar-content {
    position: fixed;
    width: 260px;
    max-height: 100%;
    overflow: auto;
    top: 48px;
    bottom: 48px;
    margin-bottom: -48px;
  }
  .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content {
    width: 56px;
  }
  .sidebar-fixed.sidebar-default .sidebar-content {
    width: 259px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    position: fixed;
    display: block;
    height: 100%;
    z-index: 1001;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
    position: fixed;
  }
  .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    width: 260px;
  }
  .sidebar-fixed-expanded .content-wrapper {
    padding-left: 56px;
  }
}
.sidebar-xs-indicator .sidebar-fixed .sidebar-content {
  top: 0!important;
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 56px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .category-title.h6 .icons-list {
    padding-top: 23.538462px;
    padding-bottom: 23.538462px;
  }
  .sidebar-xs .sidebar-main .category-title.h5 .icons-list {
    padding-top: 25.0769236px;
    padding-bottom: 25.0769236px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #26A69A;
    border: 1px solid #26A69A;
    padding: 11px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main .navigation > li > a > span .badge {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: transparent;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation > li .has-ul > span {
    border-radius: 0 3px 0 0;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > a > span {
    display: block;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 3px 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a {
    padding-left: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a:not(.has-ul) {
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user .category-content {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-main .navigation-lg > li > ul {
    top: 48px;
  }
  .sidebar-xs .sidebar-main .navigation-sm > li > ul {
    top: 40px;
  }
  .sidebar-xs .sidebar-main .navigation-xs > li > ul {
    top: 36px;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .badge {
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li:hover:not(.active) > a {
    background-color: #f5f5f5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
.sidebar .nav-tabs {
  border-width: 0 0 1px 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs {
    border-width: 1px;
  }
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #ddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #ddd;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-justified > li:first-child .dropdown-menu:not(.dropdown-menu-right) {
    left: -1px;
  }
  .sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
    right: -1px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .page-container {
      height: 1px;
    }
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
.content {
  padding: 0 20px 60px 20px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.content:first-child {
  padding-top: 20px;
}
body[class*=navbar-bottom] .content {
  padding-bottom: 20px;
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  height: 36px;
  padding: 7px 0;
  line-height: 1.5384616;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--single:hover,
.select2-selection--single:focus,
.select2-container--open .select2-selection--single {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.select2-selection--single:not([class*=bg-]) {
  background-color: #fff;
  color: #333333;
}
.select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--single[class*=bg-]:hover,
.select2-selection--single[class*=bg-]:focus,
.select2-container--open .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
}
.select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: #fff;
}
.select2-container--disabled .select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 12px;
  padding-right: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-selection--single .select2-selection__rendered > i {
  margin-right: 10px;
}
.select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  font-size: 0;
  line-height: 1;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--single .select2-selection__clear:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-selection--single .select2-selection__clear:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-selection--single .select2-selection__arrow:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: '\e9c6';
}
.select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container--disabled .select2-selection--single:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--multiple:not([class*=bg-]) {
  background-color: #fff;
}
.select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--multiple .select2-selection__rendered {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0 2px 2px 2px;
  width: 100%;
}
.select2-selection--multiple .select2-selection__placeholder {
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] .select2-selection__choice {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled .select2-selection--multiple .select2-selection__choice,
.select2-container--disabled .select2-selection--multiple .select2-search__field {
  cursor: not-allowed;
}
.select2-selection--multiple .select2-selection__choice {
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 2px;
  margin-top: 2px;
  padding: 7px 12px;
}
.select2-selection--multiple .select2-selection__choice > i {
  margin-right: 10px;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  cursor: pointer;
  float: right;
  font-size: 14px;
  margin-top: 3px;
  line-height: 1;
  margin-left: 7px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
.select2-selection--multiple .select2-search--inline {
  float: left;
}
.select2-selection--multiple .select2-search--inline .select2-search__field {
  font-size: 100%;
  margin-top: 2px;
  padding: 7px 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  margin-left: 7px;
  -webkit-appearance: textfield;
}
.select2-selection--multiple .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  color: #333333;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results > .select2-results__options {
  padding-bottom: 7px;
  max-height: 250px;
  overflow-y: auto;
}
.select2-search--hide + .select2-results > .select2-results__options {
  padding-top: 7px;
}
.select2-results:first-child > .select2-results__options {
  padding-top: 7px;
}
.select2-results__option {
  padding: 7px 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results__option + .select2-results__option {
  margin-top: 1px;
}
.select2-results__option i {
  margin-right: 10px;
}
.select2-results__option i.icon-undefined {
  display: none;
}
.select2-results__option[role=group] {
  padding: 0;
}
.select2-results__option.select2-results__option--highlighted {
  background-color: #f5f5f5;
}
.select2-results__option[aria-disabled=true] {
  color: #999999;
  cursor: not-allowed;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.6);
}
.select2-results__option[aria-selected=true] {
  background-color: #2196F3;
  color: #fff;
}
.select2-results__options--nested > .select2-results__option {
  padding-left: 24px;
  padding-right: 24px;
}
.select2-results__group {
  display: block;
  padding: 7px 12px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  cursor: default;
  margin-top: 7px;
  margin-bottom: 7px;
}
.select2-results__option:first-child > .select2-results__group {
  margin-top: 0;
}
.select2-results__message {
  color: #999999;
  cursor: default;
}
.select2-dropdown[class*=bg-] .select2-results__message {
  color: rgba(255, 255, 255, 0.75);
}
.select2-results__option.loading-results {
  padding-top: 0;
}
.select2-results__option.loading-results + .select2-results__option {
  margin-top: 7px;
}
.select2-results__option--load-more {
  text-align: center;
  margin-top: 7px;
  cursor: default;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--above[class*=bg-] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown--below[class*=bg-] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 12px;
}
.select2-search--dropdown:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-search--dropdown + .select2-results .select2-results__message:first-child {
  padding-top: 0;
}
.select2-search--dropdown .select2-search__field {
  height: 36px;
  padding: 7px 12px;
  padding-left: 36px;
  border-radius: 3px;
  border: 1px solid #ddd;
  outline: 0;
  width: 100%;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select-lg.select2-selection--single {
  height: 40px;
  padding: 9px 0;
  font-size: 14px;
}
.select-lg.select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 34px;
}
.select-lg.select2-selection--single .select2-selection__arrow:after {
  right: 15px;
}
.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 9px 15px;
  font-size: 14px;
}
.select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 9px 0;
  font-size: 14px;
}
.select-sm.select2-selection--single {
  height: 34px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--single .select2-selection__rendered {
  padding-left: 11px;
  padding-right: 26px;
}
.select-sm.select2-selection--single .select2-selection__arrow:after {
  right: 11px;
}
.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 6px 0;
}
.select-xs.select2-selection--single {
  height: 32px;
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--single .select2-selection__rendered {
  padding-left: 10px;
  padding-right: 24px;
}
.select-xs.select2-selection--single .select2-selection__arrow:after {
  right: 10px;
}
.select-xs.select2-selection--multiple .select2-selection__choice {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select2-selection--multiple[class*=bg-] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.25);
}
.select2-dropdown[class*=bg-] .select2-search--dropdown .select2-search__field {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 0, 0, 0.2);
}
.select2-dropdown[class*=bg-] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important;
}
.select2-result-repository {
  padding-top: 7px;
  padding-bottom: 7px;
}
.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 15px;
}
.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}
.select2-result-repository__meta {
  margin-left: 70px;
}
.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 12px;
}
.select2-result-repository__description {
  font-size: 12px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 15px;
}
/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Super simple WYSIWYG Editor for Bootstrap framework
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../css/icons/summernote//summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
  src: url("../css/icons/summernote//summernote.eot?#iefix") format("embedded-opentype"), url("../css/icons/summernote//summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"), url("../css/icons/summernote//summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrows-alt:before {
  content: "\f108";
}
.note-icon-bold:before {
  content: "\f109";
}
.note-icon-caret:before {
  content: "\f10a";
}
.note-icon-chain-broken:before {
  content: "\f10b";
}
.note-icon-circle:before {
  content: "\f10c";
}
.note-icon-close:before {
  content: "\f10d";
}
.note-icon-code:before {
  content: "\f10e";
}
.note-icon-eraser:before {
  content: "\f10f";
}
.note-icon-font:before {
  content: "\f110";
}
.note-icon-frame:before {
  content: "\f111";
}
.note-icon-italic:before {
  content: "\f112";
}
.note-icon-link:before {
  content: "\f113";
}
.note-icon-magic:before {
  content: "\f114";
}
.note-icon-menu-check:before {
  content: "\f115";
}
.note-icon-minus:before {
  content: "\f116";
}
.note-icon-orderedlist:before {
  content: "\f117";
}
.note-icon-pencil:before {
  content: "\f118";
}
.note-icon-picture:before {
  content: "\f119";
}
.note-icon-question:before {
  content: "\f11a";
}
.note-icon-redo:before {
  content: "\f11b";
}
.note-icon-special-character:before {
  content: "\f11c";
}
.note-icon-square:before {
  content: "\f11d";
}
.note-icon-strikethrough:before {
  content: "\f11e";
}
.note-icon-subscript:before {
  content: "\f11f";
}
.note-icon-summernote:before {
  content: "\f120";
}
.note-icon-superscript:before {
  content: "\f121";
}
.note-icon-table:before {
  content: "\f122";
}
.note-icon-text-height:before {
  content: "\f123";
}
.note-icon-trash:before {
  content: "\f124";
}
.note-icon-underline:before {
  content: "\f125";
}
.note-icon-undo:before {
  content: "\f126";
}
.note-icon-unorderedlist:before {
  content: "\f127";
}
.note-icon-video:before {
  content: "\f128";
}
.note-editor {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 1;
  display: none;
  color: #2196F3;
  background-color: #fff;
  border: 2px dashed #2196F3;
  pointer-events: none;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #2196F3;
  border: 2px dashed #2196F3;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-toolbar {
  background-color: #fcfcfc;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  padding-top: 0;
  margin: 0;
  border-radius: 3px;
}
.note-editor.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
}
.note-editor.fullscreen .note-editable {
  background-color: #fff;
}
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-editable {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-editor .note-statusbar {
  background-color: #fcfcfc;
}
.note-editor .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  cursor: s-resize;
  border-top: 1px solid #ddd;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #aaaaaa;
}
.note-editor .note-editable {
  padding: 20px;
  overflow: auto;
  outline: 0;
  min-height: 150px;
}
.note-editor .note-editable[contenteditable="false"] {
  background-color: #eeeeee;
}
.note-editor .note-codable {
  display: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  color: #fff;
  background-color: #333333;
  border: 0;
  border-radius: 0;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-editor .modal-title {
  font-size: 17px;
}
.note-air-editor {
  outline: 0;
}
.note-popover {
  max-width: none;
}
.note-popover .popover-content {
  padding: 15px;
  padding-top: 0;
  margin: 0;
}
.note-popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover .popover-content > .btn-group:last-child {
  margin-right: 0;
}
.note-popover .arrow {
  left: 20px;
}
.note-popover .popover-content > .note-btn-group,
.note-toolbar > .note-btn-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 0;
}
.note-popover .popover-content > .note-btn-group .note-icon-caret:before,
.note-toolbar > .note-btn-group .note-icon-caret:before {
  width: 9px;
  margin-left: 2px;
  margin-right: 2px;
}
.note-popover .popover-content > .note-btn-group i + .note-icon-caret,
.note-toolbar > .note-btn-group i + .note-icon-caret {
  margin-left: 2px;
  margin-right: -5px;
}
.note-popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover-content .note-table,
.note-toolbar .note-table {
  min-width: 0;
}
.note-popover .popover-content .note-table.dropdown-menu,
.note-toolbar .note-table.dropdown-menu {
  padding: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker,
.note-toolbar .note-table .note-dimension-picker {
  font-size: 18px;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute!important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative!important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute!important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-display,
.note-toolbar .note-table .note-dimension-display {
  text-align: center;
}
.note-popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 330px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 7px 12px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin-bottom: 10px;
  font-weight: 500;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 6px 11px;
  cursor: pointer;
  background-color: #fcfcfc;
  text-align: center;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 290px;
  padding: 10px;
  text-align: center;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 10px;
}
.note-popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right:before,
.note-toolbar .dropdown-menu.right:before {
  right: 9px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu.right:after,
.note-toolbar .dropdown-menu.right:after {
  right: 10px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  display: none;
}
.note-popover .popover-content .dropdown-menu li a.checked,
.note-toolbar .dropdown-menu li a.checked {
  background-color: #f5f5f5;
}
.note-popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 17px;
  height: 17px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #333333;
}
.note-popover .popover-content > .btn-group {
  margin-top: 15px;
  margin-right: 15px;
}
.note-dialog > div {
  display: none;
}
.note-dialog .note-image-dialog .mote-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 25px;
  color: #eeeeee;
  text-align: center;
  border: 4px dashed #eeeeee;
}
.note-dialog .note-help-dialog {
  color: #ccc;
  background-color: transparent;
  background-color: #333333 !important;
  border: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog a {
  color: #fff;
}
.note-dialog .note-help-dialog .title {
  padding-bottom: 7px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.note-dialog .note-help-dialog .modal-content {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-dialog .note-help-dialog .modal-close {
  color: #999999;
  cursor: pointer;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
  width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
  vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
  margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
  color: #eeeeee;
  text-align: left;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
  min-width: 110px;
  padding-right: 10px;
  font-family: "Courier New";
  color: #eeeeee;
  text-align: right;
}
.note-group-select-from-files label {
  display: block;
}
.note-handle {
  position: relative;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #333333;
  z-index: 10;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #333333;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: 0;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #333333;
  border-radius: 3px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
 *
 *  # PNotify notifications
 *
 *  Styles for pnotify.min.js - a flexible JavaScript notification plugin
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-pnotify {
  top: 20px;
  right: 20px;
  position: absolute;
  height: auto;
  z-index: 2;
  border-radius: 3px;
}
body > .ui-pnotify {
  position: fixed;
  z-index: 100040;
}
.ui-pnotify.alert-rounded > .ui-pnotify-container {
  border-radius: 100px;
}
.ui-pnotify[class*=bg-] > .ui-pnotify-container {
  background-color: inherit;
  border-color: transparent;
  color: #fff;
}
.ui-pnotify[class*=text-] > .ui-pnotify-container,
.ui-pnotify[class*=alpha-] > .ui-pnotify-container {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.ui-pnotify.stack-top-left,
.ui-pnotify.stack-bottom-left {
  left: 20px;
  right: auto;
}
.ui-pnotify.stack-bottom-right,
.ui-pnotify.stack-bottom-left {
  bottom: 20px;
  top: auto;
}
.ui-pnotify.stack-modal {
  left: 50%;
  right: auto;
  margin-left: -150px;
}
.ui-pnotify.stack-custom-right {
  top: auto;
  left: auto;
  bottom: 200px;
  right: 200px;
}
.ui-pnotify.stack-custom-left {
  top: 200px;
  left: 200px;
  right: auto;
  bottom: auto;
}
.ui-pnotify.stack-custom-top {
  right: 0;
  left: 0;
  top: 0;
}
.ui-pnotify.stack-custom-bottom {
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
}
.ui-pnotify.ui-pnotify-in {
  display: block!important;
}
.ui-pnotify.ui-pnotify-move {
  -webkit-transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-slow {
  -webkit-transition: opacity linear 0.6s;
  -o-transition: opacity linear 0.6s;
  transition: opacity linear 0.6s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-slow.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-normal {
  -webkit-transition: opacity linear 0.4s;
  -o-transition: opacity linear 0.4s;
  transition: opacity linear 0.4s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-normal.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-fast {
  transition: opacity .2s linear;
  -webkit-transition: opacity linear 0.2s;
  -o-transition: opacity linear 0.2s;
  transition: opacity linear 0.2s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-fast.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.ui-pnotify-container {
  padding: 15px 20px;
  height: 100%;
  position: relative;
  left: 0;
  margin: 0;
  border-radius: 3px;
}
.ui-pnotify-container:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.ui-pnotify-container.ui-pnotify-sharp {
  border-radius: 0;
}
.ui-pnotify-title {
  display: block;
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 15px;
}
.ui-pnotify-text {
  display: block;
}
.ui-pnotify-icon {
  display: block;
  float: left;
  line-height: 1;
}
.ui-pnotify-icon > [class^=icon-] {
  margin-top: 4px;
  margin-right: 15px;
}
.ui-pnotify-closer,
.ui-pnotify-sticker {
  float: right;
  margin-left: 8px;
  margin-top: 4px;
  line-height: 1;
  outline: 0;
}
.ui-pnotify-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
body > .ui-pnotify-modal-overlay {
  position: fixed;
  z-index: 100039;
}
.brighttheme {
  border: 1px solid;
}
.ui-pnotify[class*=bg-] > .brighttheme {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.brighttheme-notice {
  background-color: #FFF3E0;
  border-color: #FF9800;
}
.brighttheme-info {
  background-color: #E3F2FD;
  border-color: #1E88E5;
}
.brighttheme-success {
  background-color: #E8F5E9;
  border-color: #4CAF50;
}
.brighttheme-error {
  background-color: #FBE9E7;
  border-color: #FF5722;
}
.brighttheme-icon-closer,
.brighttheme-icon-sticker {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
  width: 10px;
  height: 10px;
  outline: 0;
}
.brighttheme-icon-closer:after,
.brighttheme-icon-sticker:after {
  content: '';
  font-family: 'Icomoon';
  font-size: 10px;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.brighttheme-icon-closer:after {
  content: "\ed6a";
}
.brighttheme-icon-sticker:after {
  content: "\ee70";
}
.brighttheme-icon-sticker.brighttheme-icon-stuck:after {
  content: "\ee75";
}
.ui-pnotify[class*=alert-styled-] {
  border-width: 0;
  padding: 0;
}
.ui-pnotify.alert-styled-left .brighttheme {
  border-left-width: 44px;
}
.ui-pnotify.alert-styled-left:after {
  left: 0;
}
.ui-pnotify.alert-styled-right .brighttheme {
  border-right-width: 44px;
}
.ui-pnotify.alert-styled-right:after {
  right: 0;
}
.brighttheme .ui-pnotify-action-bar {
  padding-top: 15px;
}
.brighttheme .ui-pnotify-action-bar textarea,
.brighttheme .ui-pnotify-action-bar input {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-bottom: 15px !important;
  color: #333333;
  padding: 7px 12px;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input {
  border-color: #fff;
  color: #fff;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea::-moz-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea:-ms-input-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input:-ms-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea::-webkit-input-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input::-webkit-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control {
  border-bottom-color: #fff;
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.ui-pnotify[class*=bg-] .form-control:-ms-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control::-webkit-input-placeholder {
  color: #fff;
}
.ui-pnotify-history-container {
  position: absolute;
  top: 0;
  right: 20px;
  width: 70px;
  border-top: none;
  padding: 0;
  z-index: 10000;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ui-pnotify-history-container.ui-pnotify-history-fixed {
  position: fixed;
}
.ui-pnotify-history-container .ui-pnotify-history-header {
  padding: 2px;
  text-align: center;
}
.ui-pnotify-history-container button {
  cursor: pointer;
  display: block;
  width: 100%;
}
.ui-pnotify-history-container .ui-pnotify-history-pulldown {
  display: block;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .ui-pnotify-mobile-able.ui-pnotify {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: auto !important;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  .ui-pnotify-mobile-able.ui-pnotify .ui-pnotify-shadow {
    border-bottom-width: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-top-left,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow {
    border-top-width: 5px;
    border-bottom-width: 1px;
  }
  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-fade {
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-hide {
    display: none !important;
  }
  .ui-pnotify-mobile-able .ui-pnotify-container {
    border-radius: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # FullCalendar
 *
 *  A JavaScript event calendar. Customizable and open source
 *
 *  Version: 1.4
 *  Latest update: Mar 20, 2017
 *
 * ---------------------------------------------------------------------------- */
.fc {
  direction: ltr;
  text-align: left;
}
body .fc {
  font-size: 1em;
}
.fc-rtl {
  text-align: right;
}
.fc-view > table {
  min-width: 769px;
}
.fc-events-container {
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 3px;
}
.fc-events-container .fc-event {
  margin: 0;
  padding: 4px 11px;
}
.fc-events-container .fc-event + .fc-event {
  margin-top: 10px;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #ddd;
}
.fc-unthemed thead,
.fc-unthemed thead td,
.fc-unthemed thead th {
  border-bottom-color: #bbb;
}
.fc-unthemed .fc-popover {
  background-color: #fff;
  border-radius: 3px;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background-color: #f5f5f5;
}
.fc-unthemed td.fc-today {
  background-color: #E8F5E9;
}
.fc-highlight {
  background-color: #e5e5e5;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-bgevent {
  background-color: #81C784;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-nonbusiness {
  background-color: #e5e5e5;
}
.fc-icon {
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fc-icon:after {
  display: inline-block;
  font-family: "icomoon";
  line-height: 1;
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-left-single-arrow:after {
  content: "\e9c0";
}
.fc-icon-right-single-arrow:after {
  content: "\e9c3";
}
.fc-icon-left-double-arrow:after {
  content: "\000AB";
}
.fc-icon-right-double-arrow:after {
  content: "\000BB";
}
.fc-icon-x:after {
  content: "\ed6b";
  font-size: 12px;
  display: block;
}
.fc-button {
  margin: 0;
  border: 1px solid #ddd;
  padding: 7px 12px;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
}
.fc-button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc-state-default {
  border: 1px solid #ddd;
  background-color: #fcfcfc;
}
.fc-state-default.fc-corner-left {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.fc-state-default.fc-corner-right {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.fc-state-hover {
  background-color: #f8f8f8;
}
.fc-state-down,
.fc-state-active {
  background-color: #f5f5f5;
}
.fc-button.fc-state-down,
.fc-button.fc-state-active {
  border-color: #ddd;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}
.fc-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.fc-button-group {
  display: inline-block;
}
.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  margin-left: 0;
}
.fc-popover {
  position: absolute;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}
.fc-popover .fc-header {
  padding: 10px;
}
.fc-popover .fc-header .fc-title {
  float: left;
}
.fc-popover .fc-header .fc-close {
  cursor: pointer;
  float: right;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  margin-top: 2px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.fc-unthemed .fc-popover .fc-header .fc-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fc-divider {
  border-style: solid;
  border-width: 1px;
}
hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-width: 1px 0;
}
.fc-clear {
  clear: both;
}
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-bg {
  bottom: 0;
}
.fc-bg table {
  height: 100%;
}
.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}
.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.fc th {
  padding: 12px 20px;
  font-weight: 500;
  text-align: center;
}
.fc td.fc-today {
  border-style: double;
}
a[data-goto] {
  cursor: pointer;
  color: #333333;
}
.fc-row {
  border-style: solid;
  border-width: 0;
  position: relative;
}
.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent;
}
.fc-row:first-child table {
  border-top: 0 hidden transparent;
}
.fc-row .fc-bg {
  z-index: 1;
}
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}
.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent;
  border: 0;
}
.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: none;
  border-color: transparent;
  border-bottom: 0;
}
.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0;
}
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
}
.fc-row .fc-helper-skeleton {
  z-index: 5;
}
.fc-scroller {
  -webkit-overflow-scrolling: touch;
}
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  width: 100%;
}
.fc-event {
  position: relative;
  display: block;
  border: 1px solid #039BE5;
  font-weight: normal;
  border-radius: 2px;
}
.fc-event,
.fc-event:hover {
  color: #fff;
  text-decoration: none;
}
.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
}
.fc-event .fc-bg {
  z-index: 1;
  background-color: #fff;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.fc-event .fc-content {
  position: relative;
  z-index: 2;
}
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
  display: none;
}
.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block;
}
.fc-event.fc-selected .fc-resizer:before {
  content: "";
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.fc-event .fc-event.fc-selected {
  z-index: 9999 !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.fc-event .fc-event.fc-selected.fc-dragging {
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.fc-event,
.fc-event-dot {
  background-color: #039BE5;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}
.fc-h-event .fc-start-resizer {
  cursor: w-resize;
  left: -1px;
}
.fc-h-event .fc-end-resizer {
  cursor: e-resize;
  right: -1px;
}
.fc-h-event.fc-selected .fc-start-resizer {
  margin-left: -4px;
}
.fc-h-event.fc-selected .fc-end-resizer {
  margin-right: -4px;
}
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  bottom: -1px;
}
.fc-h-event.fc-selected .fc-resizer {
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  top: 50%;
  margin-top: -4px;
}
.fc-day-grid-event {
  margin: 0 5px 5px 5px;
  padding: 4px 8px;
}
.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-color: #000;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-day-grid-event .fc-time {
  font-weight: 500;
}
.fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-left: -2px;
}
.fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-right: -2px;
}
@media (min-width: 1200px) {
  .fc-day-grid-event > .fc-content {
    background-color: inherit;
  }
  .fc-day-grid-event .fc-time {
    float: right;
    padding-left: 5px;
    display: inline-block;
    z-index: 2;
    position: relative;
    background-color: inherit;
  }
  .fc-day-grid-event .fc-title {
    z-index: 1;
    position: relative;
  }
}
a.fc-more {
  margin: 1px 5px;
  background-color: #fafafa;
  display: block;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: #999999;
  font-size: 12px;
}
a.fc-more:hover {
  background-color: #f5f5f5;
  color: #333333;
}
.fc-limited {
  display: none;
}
.fc-day-grid .fc-row {
  z-index: 1;
}
.fc-more-popover {
  z-index: 2;
  width: 220px;
}
.fc-more-popover .fc-event-container {
  padding: 10px 5px;
}
.fc-now-indicator {
  position: absolute;
  border: 0 solid #EF5350;
}
.fc-unselectable {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fc-toolbar {
  text-align: center;
}
.fc-toolbar.fc-header-toolbar {
  margin-bottom: 20px;
}
.fc-toolbar.fc-footer-toolbar {
  margin-top: 20px;
}
.fc-toolbar h2 {
  margin: 5px 0;
  font-size: 17px;
}
.fc-toolbar .fc-left {
  float: left;
  width: 33%;
}
.fc-toolbar .fc-right {
  float: right;
  width: 33%;
}
.fc-toolbar .fc-right .fc-button-group {
  float: right;
}
.fc-toolbar .fc-left > .fc-button,
.fc-toolbar .fc-right > .fc-button {
  margin-left: 10px;
}
.fc-toolbar .fc-center {
  display: inline-block;
  vertical-align: middle;
}
.fc-toolbar > * > * {
  float: left;
}
.fc-toolbar > * > :first-child {
  margin-left: 0;
}
.fc-toolbar button {
  position: relative;
}
.fc-toolbar button:focus {
  z-index: 5;
}
.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}
.fc-toolbar .fc-state-down {
  z-index: 3;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}
.fc-view-container {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  overflow: auto;
  max-width: 100%;
}
.fc-view-container .fc-view > table th,
.fc-view-container .fc-view > table td {
  border-right-width: 0;
}
.fc-view-container .fc-view > table tr:first-child th:first-child,
.fc-view-container .fc-view > table tr:first-child td:first-child {
  border-left-width: 0;
}
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 10px;
}
.fc-basic-view tbody .fc-row {
  min-height: 100px;
  min-width: 100px;
}
.fc-row.fc-rigid {
  overflow: hidden;
}
.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 10px;
}
.fc-basic-view .fc-week-number {
  text-align: center;
}
.fc-basic-view .fc-week-number span {
  display: inline-block;
  min-width: 1.25em;
}
td.fc-basic-view .fc-week-number {
  text-align: center;
}
td.fc-basic-view .fc-week-number > * {
  display: inline-block;
  min-width: 1.25em;
}
.fc-basic-view .fc-day-number {
  text-align: right;
}
.fc-basic-view .fc-day-top .fc-day-number {
  float: right;
}
.fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  min-width: 1.5em;
  text-align: center;
  background-color: #f5f5f5;
  color: #999999;
  border-radius: 0 0 3px 0;
}
.fc-other-month.fc-day-top,
.fc-other-month.fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}
.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
}
.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 0;
}
.fc-axis {
  text-align: right;
}
.fc .fc-axis {
  vertical-align: middle;
  padding: 10px;
  white-space: nowrap;
}
.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}
.fc-time-grid {
  min-height: 100%;
}
.fc-time-grid table {
  border: 0 hidden transparent;
}
.fc-time-grid > .fc-bg {
  z-index: 1;
}
.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-content-col {
  position: relative;
}
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}
.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}
.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}
.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}
.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6;
}
.fc-time-grid .fc-slats td {
  border-bottom: 0;
}
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
}
.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  position: relative;
}
.fc-time-grid .fc-event {
  margin: 5px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
}
.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  padding: 10px;
  min-height: 45px;
}
.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}
.fc-v-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.fc-v-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-time-grid-event {
  overflow: hidden;
}
.fc-time-grid-event.fc-selected {
  overflow: visible;
}
.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
}
.fc-time-grid-event .fc-content {
  position: relative;
  overflow: hidden;
}
.fc-time-grid-event .fc-time {
  font-weight: 500;
}
.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}
.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
}
.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}
.fc-time-grid-event.fc-short .fc-title {
  font-size: 12px;
  padding: 0;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 10px;
  font-size: 12px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}
.fc-time-grid-event.fc-selected .fc-resizer {
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background-color: #fff;
  left: 50%;
  margin-left: -5px;
  bottom: -5px;
}
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc-event-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.fc-list-view {
  border-width: 1px 0;
  border-style: solid;
  direction: ltr;
}
.fc .fc-list-table {
  table-layout: auto;
}
.fc-list-table td {
  border-width: 1px 0 0;
  padding: 12px 20px;
}
.fc-list-table tr:first-child td {
  border-top-width: 0;
}
.fc-list-heading {
  border-bottom-width: 1px;
}
.fc-list-heading td {
  font-weight: 500;
}
.fc-list-heading-main {
  float: left;
}
.fc-list-heading-alt {
  float: right;
}
.fc-list-item.fc-has-url {
  cursor: pointer;
}
.fc-list-item:hover td {
  background-color: #fafafa;
}
.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}
.fc-list-item-marker {
  padding-right: 0;
}
.fc-list-item-title a {
  text-decoration: none;
  color: inherit;
}
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}
.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #fafafa;
}
@media (max-width: 768px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-left > .fc-button,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-right > .fc-button,
  .fc-toolbar .fc-left .fc-button-group,
  .fc-toolbar .fc-right .fc-button-group {
    float: none;
    width: auto;
    vertical-align: top;
  }
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    display: block;
    margin-bottom: 10px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  File input styling for Bootstrap 3.0
 *
 *  Version: 1.3
 *  Latest update: Aug 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.file-input.has-error .form-control {
  border-color: #ddd;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 769px) {
  .file-input .btn > [class*=icon-],
  .file-input .btn > .glyphicon {
    margin-right: 5px;
  }
}
.file-object {
  margin: 0 0 -7px 0;
  padding: 0;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
}
.file-caption-name {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
}
.file-caption-name [class*=icon-],
.file-caption-name .glyphicon {
  margin-right: 7px;
}
.file-input-new .file-caption-name {
  color: #999999;
}
.file-input-new .file-caption-name > [class*=icon-],
.file-input-new .file-caption-name > .glyphicon {
  display: none;
}
.file-error-message {
  position: relative;
  background-color: #f2dede;
  color: #a94442;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px 5px 5px;
}
.file-drop-zone .file-error-message {
  margin-top: 5px;
}
.file-error-message .close {
  margin-top: 1px;
  color: #a94442;
}
.file-error-message pre,
.file-error-message ul {
  margin: 7px 0;
  text-align: left;
}
.file-caption-disabled {
  background-color: #fafafa;
  cursor: not-allowed;
}
.file-caption-disabled .file-caption-name {
  color: #ccc;
}
.file-preview-detail-modal {
  text-align: left;
}
.file-preview {
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}
.file-preview > .close {
  font-weight: 400;
  font-size: 17px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 8px;
  line-height: 0.6;
  border-radius: 3px;
  color: #555;
  opacity: 1;
  filter: alpha(opacity=100);
}
.file-preview-frame {
  margin: 7px;
  margin-right: 0;
  display: table;
  border: 1px solid #ddd;
  float: left;
  vertical-align: middle;
}
.file-drop-zone .file-preview-frame {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.file-preview-image {
  height: 160px;
  vertical-align: middle;
  image-orientation: from-image;
  border-radius: 3px;
}
@media (max-width: 768px) {
  .file-preview-image {
    max-width: 100%;
  }
}
.file-preview-text {
  text-align: left;
  width: 160px;
  margin-bottom: 2px;
  color: #428bca;
  background-color: #fff;
  overflow-x: hidden;
}
.file-preview-other {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 160px;
  height: 160px;
}
.file-preview-other:after {
  content: "\ea0c";
  font-family: "icomoon";
  display: block;
  font-size: 64px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.file-preview-other .glyphicon {
  display: none;
}
.file-preview-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #333333;
  background-color: rgba(250, 250, 250, 0.8);
  border-top: 1px solid #ddd;
  padding: 7px 12px;
  font-size: 12px;
  line-height: 1.6666667;
  color: #333333 !important;
}
.file-preview-status:empty {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.file-thumb-loading {
  min-height: 107px;
}
.file-thumb-loading:before,
.file-uploading:before {
  content: "";
  display: inline-block;
  background-color: #263238;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5px 0 0 -16px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
}
.file-thumb-loading:after,
.file-uploading:after {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  margin: -25.5px 0 0 -8px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.file-upload-indicator {
  position: absolute;
  bottom: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: default;
  text-align: left;
  padding-left: 8px;
}
.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
  display: none;
}
.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove,
.file-input-ajax-new .file-caption-name > [class*=icon-],
.file-input-ajax-new .file-caption-name > .glyphicon {
  display: none;
}
.file-actions {
  margin-top: 5px;
  border-top: 1px solid #eeeeee;
}
.file-actions .btn > [class*=icon-],
.file-actions .btn > .glyphicon {
  margin-right: 0;
}
.file-footer-buttons {
  float: right;
}
.file-footer-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 11px;
  padding: 12px 7px 7px 7px;
  margin-left: auto;
  margin-right: auto;
}
.file-preview-error {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-drop-zone {
  border: 1px dashed #ddd;
  border-radius: 3px;
  height: 100%;
  vertical-align: middle;
  margin: 5px;
  padding: 5px;
}
.file-drop-zone-title {
  color: #999999;
  font-size: 21px;
  font-weight: 300;
  padding: 85px 10px;
}
.file-highlighted {
  border-color: #ccc;
  background-color: #fafafa;
}
.kv-upload-progress {
  margin-bottom: 20px;
}
.file-thumb-progress {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
}
.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
  height: 5px;
  border-radius: 0;
  font-size: 0;
}
.file-thumbnail-footer {
  position: relative;
}
.btn-file ::-ms-browse {
  width: 100%;
  height: 100%;
}
.file-zoom-fullscreen.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.file-zoom-fullscreen .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.file-zoom-fullscreen .modal-content {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-zoom-fullscreen .modal-body {
  overflow-y: auto;
}
.file-zoom-dialog .btn-navigate {
  position: absolute;
  top: 50%;
  margin-top: -27px;
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.file-zoom-dialog .btn-navigate > i {
  display: block;
  font-size: 32px;
}
.file-zoom-dialog .btn-navigate:not([disabled]):hover,
.file-zoom-dialog .btn-navigate:not([disabled]):focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.file-zoom-dialog .btn-navigate[disabled] {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.file-zoom-dialog .btn-prev {
  left: -42px;
}
.file-zoom-dialog .btn-next {
  right: -42px;
}
.file-zoom-dialog .floating-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 0 0 0 3px;
}
.file-zoom-dialog .floating-buttons:not(:empty) {
  padding: 0 0 20px 20px;
}
.file-zoom-dialog .floating-buttons,
.file-zoom-dialog .floating-buttons .btn {
  z-index: 3000;
}
.kv-zoom-actions {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.file-zoom-content {
  height: 480px;
  text-align: center;
}
.file-drag-handle {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: move;
  cursor: -webkit-grabbing;
}
.file-drag-handle:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.file-drag-handle > i {
  top: 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Open source library that provides drag’n’drop file uploads with image previews
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  border: 2px dashed #ddd;
  min-height: 250px;
  border-radius: 2px;
  background-color: #fff;
  padding: 5px;
}
.panel .dropzone {
  background-color: #fcfcfc;
  border-color: #eeeeee;
}
.dropzone.dz-clickable,
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message span {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone .dz-message {
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-color: rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.04);
}
.sidebar .dropzone {
  min-height: 190px;
  text-align: center;
  border-width: 2px;
}
.sidebar .dropzone .dz-default.dz-message:before {
  font-size: 64px;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  top: 50px;
}
.sidebar .dropzone .dz-default.dz-message span {
  font-size: 15px;
}
.sidebar .dropzone .dz-default.dz-message > span {
  margin-top: 130px;
}
@media (min-width: 769px) {
  .dropzone {
    min-height: 320px;
  }
}
.dropzone .dz-default.dz-message {
  position: absolute;
  height: 230px;
  width: 100%;
  margin-top: -115px;
  top: 50%;
  left: 0;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-default.dz-message:before {
  content: '\ea0e';
  font-family: 'icomoon';
  font-size: 64px;
  position: absolute;
  top: 48px;
  width: 64px;
  height: 64px;
  display: inline-block;
  left: 50%;
  margin-left: -32px;
  line-height: 1;
  z-index: 2;
  color: #ddd;
  text-indent: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-default.dz-message span {
  font-size: 15px;
  color: #bbb;
  text-align: center;
  margin-top: 140px;
  display: block;
  font-weight: 500;
  text-shadow: 0 1px 1px #fff;
}
.dropzone .dz-default.dz-message span > span {
  margin-top: 2px;
  display: block;
  color: #ccc;
  font-size: 15px;
}
@media (min-width: 769px) {
  .dropzone .dz-default.dz-message:before {
    font-size: 80px;
    top: 48px;
    width: 80px;
    height: 80px;
    margin-left: -32px;
  }
  .dropzone .dz-default.dz-message span {
    font-size: 19px;
    margin-top: 130px;
  }
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.15;
  filter: alpha(opacity=15);
}
.dropzone.dz-started .dz-message {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
}
.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 2px;
}
.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
  display: none;
}
.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
  width: 140px;
  position: relative;
  background-color: #fafafa;
  padding: 8px;
  margin-bottom: 25px;
}
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
  overflow: hidden;
  height: 100%;
}
.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 140px;
}
.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
  position: absolute;
  bottom: -26px;
  left: 0;
}
.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  display: none;
  position: absolute;
  z-index: 9;
  right: 8px;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  font-family: 'icomoon';
  display: block;
  position: absolute;
  right: 0;
  bottom: 3px;
  font-size: 12px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-success-mark svg {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
  color: #F44336;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
  content: '\ed6a';
}
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  color: #4CAF50;
}
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  content: '\ed6e';
}
.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  top: 118px;
  left: 8px;
  right: 8px;
  height: 4px;
  background-color: #eeeeee;
  display: none;
  border-radius: 2px;
}
.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #00BCD4;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(245, 245, 245, 0.8);
  margin: 8px;
  padding: 8px;
  color: #F44336;
  max-width: 100%;
  z-index: 5;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
  background: #F44336;
}
.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
  display: block;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
  background-color: #fafafa;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  display: block;
  padding: 5px;
  text-align: center;
  color: #999999;
  margin-top: 35px;
  cursor: pointer;
}
.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
  color: #333333;
  background-color: #f5f5f5;
}
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
  border-collapse: separate;
}
.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 40px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: '';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 12px;
  margin-top: -6px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable thead .sorting:before {
  content: '\e9c1';
  margin-top: -2px;
  color: #999999;
}
.dataTable thead .sorting:after {
  content: '\e9c2';
  margin-top: -10px;
  color: #999999;
}
.dataTable thead .sorting_asc:after {
  content: '\e9c2';
}
.dataTable thead .sorting_desc:after {
  content: '\e9c1';
}
.dataTable thead .sorting_asc_disabled:after {
  content: '\e9c2';
  color: #ccc;
}
.dataTable thead .sorting_desc_disabled:after {
  content: '\e9c1';
  color: #ccc;
}
.dataTable tbody > tr:first-child > th,
.dataTable tbody > tr:first-child > td {
  border-top: 0;
}
.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > td {
  border-top: 0;
}
.dataTable .dataTables_empty {
  text-align: center;
}
.dataTable.table-bordered {
  border-collapse: collapse;
}
.dataTable .media-body {
  width: auto;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper .table-bordered {
  border-top: 0;
}
.dataTables_wrapper + .panel-body {
  border-top: 1px solid #ddd;
}
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.datatable-header,
.datatable-footer {
  padding: 20px 20px 0 20px;
}
.datatable-header:after,
.datatable-footer:after {
  content: "";
  display: table;
  clear: both;
}
.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}
.panel-body .datatable-header,
.panel-body .datatable-footer {
  padding-left: 0;
  padding-right: 0;
}
.datatable-header-accent,
.datatable-footer-accent {
  background-color: #fcfcfc;
}
.datatable-header {
  border-bottom: 1px solid #ddd;
}
.datatable-footer {
  border-top: 1px solid #bbb;
}
.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dataTables_length > label {
  margin-bottom: 0;
}
.dataTables_length > label > span:first-child {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_length select {
  height: 36px;
  padding: 7px 12px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  outline: 0;
}
.length-left .dataTables_length {
  float: left;
}
.dataTables_length .select2-container {
  width: auto;
}
.dataTables_length .select2-choice {
  min-width: 60px;
}
.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 20px 20px;
}
.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}
.dataTables_filter > label:after {
  content: "\e98e";
  font-family: 'icomoon';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTables_filter > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 36px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.filter-right .dataTables_filter {
  float: right;
}
.dataTables_info {
  float: left;
  padding: 8px 0;
  margin-bottom: 20px;
}
.info-right .dataTables_info {
  float: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 7px;
  min-width: 36px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbb;
}
.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 12px;
  padding-right: 12px;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}
.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before,
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
  content: none;
}
.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}
.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.panel-body + .dataTables_wrapper,
.panel-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd;
}
.panel-body > .dataTables_wrapper .datatable-footer {
  border-top: 0;
}
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}
.panel-flat > .panel-heading + .dataTables_wrapper > .datatable-header {
  padding-top: 0;
}
.panel > .dataTables_wrapper .table-bordered {
  border: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}
.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%;
}
.datatable-scroll-wrap {
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none!important;
    text-align: center;
    margin-left: 0;
  }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }
  .datatable-header {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Select extension
*
*  Spreadsheets such as Excel and Google Docs have a very handy data duplication
*  option of an auto fill tool
*
*  Version: 1.1
*  Latest update: Dec 9, 2015
*
* ---------------------------------------------------------------------------- */
.dataTable tbody > tr.selected,
.dataTable tbody > tr > .selected {
  background-color: #E8F5E9;
}
.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .dataTables_wrapper .select-info,
  .dataTables_wrapper .select-item {
    margin-left: 0;
    display: block;
  }
}
.dataTable tbody .select-checkbox {
  position: relative;
}
.dataTable tbody .select-checkbox:before,
.dataTable tbody .select-checkbox:after {
  display: inline-block;
  color: #455A64;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}
.dataTable tbody .select-checkbox:before {
  content: '';
  background-color: #fff;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-left: -9px;
  border: 2px solid #607D8B;
  border-radius: 2px;
}
.dataTable tbody .selected .select-checkbox:after {
  content: "\e600";
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  margin-top: -8px;
  margin-left: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ------------------------------------------------------------------------------
*
*  # Buttons extension
*
*  The Buttons extension for DataTables provides a common set of options, API
*  methods and styling to display buttons that will interact with a DataTable
*
*  Version: 1.2
*  Latest update: Jul 5, 2016
*
* ---------------------------------------------------------------------------- */
.dt-buttons-full .dt-buttons {
  text-align: center;
  float: none;
  display: block;
  margin: 0;
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fcfcfc;
}
.dt-buttons-full .dt-buttons > .btn {
  margin-bottom: 10px;
  float: none;
}
.dt-buttons {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dt-buttons-left .dt-buttons {
  float: left;
}
.dt-buttons > .dt-button {
  display: inline-block;
}
.dt-buttons > .btn {
  border-radius: 0;
}
.dt-buttons > .btn:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.dt-buttons > .btn:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.dt-buttons > .btn + .btn {
  margin-left: -1px;
}
@media screen and (max-width: 767px) {
  .dt-buttons {
    float: none;
    text-align: center;
    display: block;
  }
  .dt-buttons .btn {
    float: none;
  }
}
.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  text-align: center;
  z-index: 1050;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.dt-button-info h2 {
  margin-top: 0;
  line-height: 1.5384616;
  font-size: 17px;
}
.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #333;
  z-index: 999;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  display: block;
  z-index: 1000;
  padding: 7px 0;
  overflow: hidden;
  min-width: 180px;
  border-radius: 3px;
  -webkit-column-gap: 2px;
  -moz-column-gap: 2px;
  -ms-column-gap: 2px;
  -o-column-gap: 2px;
  column-gap: 2px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.dt-button-collection > .dt-button {
  padding: 8px 15px;
  color: #333333;
  display: block;
  outline: 0;
}
.dt-button-collection > .dt-button + .dt-button {
  margin-top: 1px;
}
.dt-button-collection > .dt-button:hover,
.dt-button-collection > .dt-button:focus {
  color: #333333;
  background-color: #f5f5f5;
}
.dt-button-collection > .dt-button.active {
  color: #fff;
  background-color: #2196F3;
}
.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  padding-left: 5px;
  padding-right: 5px;
}
.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}
.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
.dt-button-collection > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
.dt-button-collection.two-column {
  width: 300px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
.dt-button-collection.three-column {
  width: 450px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
.dt-button-collection.four-column {
  width: 600px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
/* ------------------------------------------------------------------------------
*
*  # Datatables Scroller
*
*  Drawing the rows required for the current display only, for fast operation
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTS tbody th,
.DTS tbody td {
  white-space: nowrap;
}
.DTS .DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 20px;
  margin-top: -20px;
  margin-left: -100px;
  z-index: 1;
  border: 1px solid #999;
  padding: 20px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.DTS .dataTables_scrollHead,
.DTS .dataTables_scrollFoot {
  background-color: #fff;
}
.DTS .dataTables_scrollBody {
  z-index: 2;
}
/* ------------------------------------------------------------------------------
*
*  # Responsive extension
*
*  Optimise the table's layout for different screen sizes through the dynamic 
*  insertion and removal of columns from the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 10px;
}
.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
  display: none;
}
.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}
.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: '\e9e4';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '\e9e7';
}
.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}
.dataTable tr.child:hover {
  background-color: transparent;
}
.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 40px;
}
.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 8px 0;
}
.dataTable tr td.child {
  white-space: normal;
  position: relative;
}
.dataTable tr td.child > ul {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dataTable tr td.child > ul > li {
  display: table-row;
}
@media (max-width: 480px) {
  .dataTable tr td.child > ul > li .dropdown,
  .dataTable tr td.child > ul > li .dropup,
  .dataTable tr td.child > ul > li .btn-group {
    position: static;
  }
  .dataTable tr td.child > ul > li .dropdown-menu {
    left: 0;
    right: 0;
    border-radius: 0;
    border-width: 1px 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  background: url('../images/login_cover.jpg') no-repeat;
  background-size: cover;
}
.login-container .page-container {
  padding-top: 40px;
  position: static;
}
.login-container .page-container .login-form {
  width: 320px;
}
.login-container .page-container .login-form .thumb {
  margin: 0 auto 20px auto;
}
.login-container .page-container .login-form,
.login-container .page-container .registration-form {
  margin: 0 auto 20px auto;
}
@media (max-width: 480px) {
  .login-container .page-container .login-form,
  .login-container .page-container .registration-form {
    width: 100%;
  }
}
.login-container .page-container .nav-tabs.nav-justified {
  margin-bottom: 0;
}
.login-container .page-container .nav-tabs.nav-justified > li > a {
  border-top: 0!important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
}
.login-container .page-container .nav-tabs.nav-justified > li:first-child > a {
  border-left: 0;
  border-radius: 3px 0 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li:last-child > a {
  border-right: 0;
  border-radius: 0 3px 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li.active > a {
  background-color: transparent;
}
@media (max-width: 768px) {
  .login-container .page-container .nav-tabs.nav-justified {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .login-container .page-container .nav-tabs.nav-justified:before {
    content: none;
  }
  .login-container .page-container .nav-tabs.nav-justified > li > a {
    border-width: 0!important;
  }
}
.login-container .footer {
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.breadcrumb-elements-toggle {
  float: right;
  cursor: pointer;
  line-height: 1;
  margin-top: 12px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  margin-right: 20px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 36px;
  margin-top: -18px;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    position: static;
    margin-top: 0;
    height: auto;
  }
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 14px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible-elements {
    top: 56px;
    background-color: #fff;
  }
}
.panel-footer > .heading-elements {
  position: static;
  margin-top: 0;
  padding-right: 20px;
}
.panel-footer > .heading-elements:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer-bordered > .heading-elements:not(.visible-elements) {
  margin-left: -20px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
    float: none!important;
  }
  .panel-footer .heading-elements-toggle {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
    display: block;
    text-align: center;
  }
}
.heading-elements.visible-elements {
  text-align: center;
}
.heading-elements.visible-elements .icons-list{
  margin-top: 20px;
}
.heading-elements.visible-elements .dropdown-menu:not(.multiselect-container) {
  left: -1px;
  right: -1px;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.heading-elements.visible-elements .multiselect-container {
  left: 0;
  right: 0;
}
.heading-elements:not(.visible-elements) .icons-list {
  margin-top: 10px;
}
.heading-form .form-group {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    display: none;
  }
  .heading-elements.visible-elements {
    display: block;
  }
  .panel .has-visible-elements {
    padding-bottom: 0;
  }
  .panel-flat .panel-heading.has-visible-elements {
    padding-bottom: 20px;
  }
  .panel .visible-elements {
    border-top: 1px solid #ddd;
    padding: 20px;
    padding-top: 0;
  }
  .panel-heading .visible-elements {
    margin: 15px -20px 0 -20px;
    background-color: #fcfcfc;
  }
  .panel-flat .panel-heading .visible-elements {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  .panel-footer .visible-elements {
    margin-top: 7px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .visible-elements.breadcrumb-elements.not-collapsible {
    display: none;
  }
  .page-header-content > .visible-elements {
    background-color: #f5f5f5;
    left: 0;
    right: 0;
    border-top: 1px solid #dcdcdc;
    z-index: 1002;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header-default .page-header-content > .visible-elements {
    background-color: #fcfcfc;
    border-color: #ddd;
  }
  .page-header-inverse .page-header-content > .visible-elements {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }
}
/** ------------------------------------------------------------------------------
*
*  # Switchery toggles
*
*  Styles for switchery.min.js - toggle switches
                                 *
                                 *  Version: 1.0
*  Latest update: Mar 25, 2015
*
* ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  width: 44px;
  height: 22px;
  vertical-align: middle;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.checkbox-switchery .switchery {
  position: absolute;
  left: 0;
  margin-top: -2px;
}
.switchery > small {
  background-color: #fff;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.checkbox.checkbox-switchery {
  margin-bottom: 14px;
  padding-left: 0;
}
.checkbox.checkbox-switchery.disabled .switchery {
  cursor: not-allowed;
}
.checkbox[class*=switchery-],
.checkbox-inline[class*=switchery-] {
  padding-left: 0;
  padding-right: 0;
}
.switchery-lg.checkbox-switchery {
  margin-bottom: 18px;
}
.switchery-lg.checkbox-switchery .switchery {
  margin-top: -4px;
}
label.switchery-lg {
  margin-bottom: 0;
}
.switchery-lg .switchery {
  height: 26px;
  width: 52px;
}
.switchery-lg .switchery > small {
  height: 26px;
  width: 26px;
}
.switchery-sm.checkbox-switchery {
  margin-bottom: 10px;
}
.switchery-sm.checkbox-switchery .switchery {
  margin-top: 0px;
}
label.switchery-sm {
  margin-bottom: 0;
}
.switchery-sm .switchery {
  height: 18px;
  width: 36px;
}
.switchery-sm .switchery > small {
  height: 18px;
  width: 18px;
}
.switchery-xs.checkbox-switchery {
  margin-bottom: 6px;
}
.switchery-xs.checkbox-switchery .switchery {
  margin-top: 2px;
}
label.switchery-xs {
  margin-bottom: 0;
}
.switchery-xs .switchery {
  height: 14px;
  width: 28px;
}
.switchery-xs .switchery > small {
  height: 14px;
  width: 14px;
}
.checkbox-switchery label,
label.checkbox-switchery {
  position: relative;
  padding-left: 56px;
  margin: 0;
  cursor: pointer;
}
.checkbox-switchery.switchery-lg label,
label.checkbox-switchery.switchery-lg {
  padding-left: 64px;
}
.checkbox-switchery.switchery-sm label,
label.checkbox-switchery.switchery-sm {
  padding-left: 48px;
}
.checkbox-switchery.switchery-xs label,
label.checkbox-switchery.switchery-xs {
  padding-left: 40px;
}
.checkbox-switchery.checkbox-inline {
  margin-bottom: 0;
}
.checkbox-switchery.checkbox-right .switchery {
  left: auto;
  right: 0;
}
.checkbox-switchery.checkbox-right label,
label.checkbox-switchery.checkbox-right {
  padding-left: 0;
  padding-right: 56px;
}
.checkbox-switchery.checkbox-right.switchery-lg label,
label.checkbox-switchery.checkbox-right.switchery-lg {
  padding-left: 0;
  padding-right: 64px;
}
.checkbox-switchery.checkbox-right.switchery-sm label,
label.checkbox-switchery.checkbox-right.switchery-sm {
  padding-left: 0;
  padding-right: 48px;
}
.checkbox-switchery.checkbox-right.switchery-xs label,
label.checkbox-switchery.checkbox-right.switchery-xs {
  padding-left: 0;
  padding-right: 40px;
}
.switchery-double .switchery {
  position: relative;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: -7px;
  margin-bottom: -5px;
}
.switchery-double.checkbox-switchery label,
label.switchery-double.checkbox-switchery {
  padding: 0;
}
.switchery-double.switchery-lg .switchery {
  margin-top: -8px;
  margin-bottom: -6px;
}
.switchery-double.switchery-sm .switchery {
  margin-top: -6px;
  margin-bottom: -4px;
}
.switchery-double.switchery-xs .switchery {
  margin-top: -5px;
  margin-bottom: -3px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 7px;
  border-radius: 3px;
  border: 1px solid transparent;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 7px 12px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #333333;
  background-color: #eeeeee;
}
.bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196F3;
}
.bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background-color: #F44336;
}
.bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background-color: #4CAF50;
}
.bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background-color: #FF5722;
}
.bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background-color: #00BCD4;
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.bootstrap-switch-label:hover {
  background-color: #fcfcfc;
}
.bootstrap-switch-label:active {
  background-color: #fafafa;
}
.bootstrap-switch-label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
  display: inline-block;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 9px 15px;
}
.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 5px 10px;
}
.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 6px 11px;
}
.checkbox-switch > label,
label.checkbox-switch.checkbox-inline {
  padding: 0;
}
.checkbox-switch.checkbox-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 7px;
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.2
*  Latest update: Mar 20, 2017
*
* ---------------------------------------------------------------------------- */
.display-block,
label.display-block {
  display: block;
}
.cursor-pointer {
  cursor: pointer;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.border-bottom {
  border-bottom: 1px solid;
}
@media (min-width: 769px) {
  .width-350 {
    min-width: 350px;
  }
}